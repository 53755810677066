export const actionType = {

    GET_SINGLE_BRANCH: "GET_SINGLE_BRANCH",
    GET_SINGLE_BRANCH_SUCCESS: "GET_SINGLE_BRANCH_SUCCESS",
    GET_SINGLE_BRANCH_FAILED: "GET_SINGLE_BRANCH_FAILED",

    POST_ADD_BRANCH: "POST_ADD_BRANCH",
    POST_ADD_BRANCH_SUCCESS: "POST_ADD_BRANCH_SUCCESS",
    POST_ADD_BRANCH_FAILED: "POST_ADD_BRANCH_FAILED",

    PUT_BRANCH: "PUT_BRANCH",
    PUT_BRANCH_SUCCESS: "PUT_BRANCH_SUCCESS",
    PUT_BRANCH_FAILED: "PUT_BRANCH_FAILED",

    // NON-API REQUEST
    REMOVE_ADD_BRANCH_TOAST: "REMOVE_ADD_BRANCH_TOAST",

}

export const actionCreators = {
    removeAddBranchToast: () => ({
        type: actionType.REMOVE_ADD_BRANCH_TOAST
    }),
    getSingleBranch: id => ({
        type: actionType.GET_SINGLE_BRANCH, id
    }),
    postAddBranch: payload => ({
        type: actionType.POST_ADD_BRANCH, payload
    }),
    putBranch: payload => ({
        type: actionType.PUT_BRANCH, payload
    })
}

const initialState = {
    meta: null,
    isLoading: false,
    error: null,
    addBranchToast: null,
    branch: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.REMOVE_ADD_BRANCH_TOAST:
            return Object.assign({}, state, { addBranchToast: null });

        case actionType.GET_SINGLE_BRANCH:
        case actionType.POST_ADD_BRANCH:
        case actionType.PUT_BRANCH:
            return Object.assign({}, state, { isLoading: true, error: null });

        case actionType.GET_SINGLE_BRANCH_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                branch: action.payload.branch
            })

        case actionType.POST_ADD_BRANCH_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                addBranchToast: {
                    message: "Successfully added a new branch!",
                    type: "success",
                    request: "post"
                }
            });

        case actionType.PUT_BRANCH_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                addBranchToast: {
                    message: "Successfully updated branch!",
                    type: "success",
                    request: "post"
                }
            });

        case actionType.GET_SINGLE_BRANCH_FAILED:
        case actionType.POST_ADD_BRANCH_FAILED:
        case actionType.PUT_BRANCH_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                addBranchToast: {
                    message: action.error ? action.error : 'Failed',
                    type: "failed",
                    request: "post"
                }
            });
        default:
            return state;
    }
}
