import { takeLatest } from "redux-saga/effects";
import processAction from "../../../../utils/processAction";
import { actionType } from "./reducer";

import {
  getFeedbacks as getFeedbacksService,
  getAllFeedbacks as getAllFeedbacksService,
  postFeedback as postFeedbackService,
  putFeedback as putFeedbackService,
  deleteFeedback as deleteFeedbackService,
} from "./service";

/************************/
/******* WORKERS ********/
/************************/

function* getFeedbacks({ payload }) {
  yield processAction({
    params: payload,
    service: getFeedbacksService,
    success: actionType.GET_FEEDBACKS_SUCCESS,
    failed: actionType.GET_FEEDBACKS_FAILED,
  });
}

function* getAllFeedbacks({}) {
  yield processAction({
    service: getAllFeedbacksService,
    success: actionType.GET_ALL_FEEDBACKS_SUCCESS,
    failed: actionType.GET_ALL_FEEDBACKS_FAILED,
  });
}

function* postFeedback({ payload }) {
  yield processAction({
    params: payload,
    service: postFeedbackService,
    success: actionType.POST_FEEDBACK_SUCCESS,
    failed: actionType.POST_FEEDBACK_FAILED,
  });
}

function* putFeedback({ payload }) {
  yield processAction({
    params: payload,
    service: putFeedbackService,
    success: actionType.PUT_FEEDBACK_SUCCESS,
    failed: actionType.PUT_FEEDBACK_FAILED,
  });
}

function* deleteFeedback({ payload }) {
  yield processAction({
    params: payload,
    service: deleteFeedbackService,
    success: actionType.DELETE_FEEDBACK_SUCCESS,
    failed: actionType.DELETE_FEEDBACK_FAILED,
  });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetFeedbacks() {
  yield takeLatest(actionType.GET_FEEDBACKS, getFeedbacks);
}

function* watchPostFeedback() {
  yield takeLatest(actionType.POST_FEEDBACK, postFeedback);
}

function* watchPutFeedback() {
  yield takeLatest(actionType.PUT_FEEDBACK, putFeedback);
}

function* watchDeleteFeedback() {
  yield takeLatest(actionType.DELETE_FEEDBACK, deleteFeedback);
}

function* watchGetAllFeedbacks() {
  yield takeLatest(actionType.GET_ALL_FEEDBACKS, getAllFeedbacks);
}

export default [
  watchGetFeedbacks(),
  watchPostFeedback(),
  watchPutFeedback(),
  watchDeleteFeedback(),
  watchGetAllFeedbacks(),
];
