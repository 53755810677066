import request from "../../../../utils/api";

const url = "feedbacks";

export const getFeedbacks = (params) =>
  request(`${url}/customer_feedbacks`, { params });

export const getAllFeedbacks = () => request(`${url}/customer_feedbacks`);

export const postFeedback = (body) => request(url, { method: "post", body });

export const putFeedback = (body) =>
  request(`${url}/${body}/read`, { method: "put" });

export const deleteFeedback = (body) =>
  request(`${url}/${body}`, { method: "delete" });
