export const actionType = {

    GET_SEGMENTS: "GET_SEGMENTS",
    GET_SEGMENTS_SUCCESS: "GET_SEGMENTS_SUCCESS",
    GET_SEGMENTS_FAILED: "GET_SEGMENTS_FAILED",

    POST_SEGMENT: "POST_SEGMENT",
    POST_SEGMENT_SUCCESS: "POST_SEGMENT_SUCCESS",
    POST_SEGMENT_FAILED: "POST_SEGMENT_FAILED",

    PUT_SEGMENT: "PUT_SEGMENT",
    PUT_SEGMENT_SUCCESS: "PUT_SEGMENT_SUCCESS",
    PUT_SEGMENT_FAILED: "PUT_SEGMENT_FAILED",

    DELETE_SEGMENT: "DELETE_SEGMENT",
    DELETE_SEGMENT_SUCCESS: "DELETE_SEGMENT_SUCCESS",
    DELETE_SEGMENT_FAILED: "DELETE_SEGMENT_FAILED",

    // NON-API REQUEST
    REMOVE_SEGMENTS_TOAST: "REMOVE_SEGMENTS_TOAST",

}

export const actionCreators = {
    removeSegmentsToast: () => ({
        type: actionType.REMOVE_SEGMENTS_TOAST
    }),
    getSegments: payload => ({
        type: actionType.GET_SEGMENTS, payload
    }),
    postSegment: payload => ({
        type: actionType.POST_SEGMENT, payload
    }),
    putSegment: payload => ({
        type: actionType.PUT_SEGMENT, payload
    }),
    deleteSegment: payload => ({
        type: actionType.DELETE_SEGMENT, payload
    }),
}

const initialState = {
    meta: null,
    isLoading: false,
    error: null,
    segmentsToast: null,
    segments: null,
}


export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.REMOVE_SEGMENTS_TOAST:
            return Object.assign({}, state, { segmentsToast: null });

        case actionType.DELETE_SEGMENTS:
        case actionType.PUT_SEGMENTS:
        case actionType.POST_SEGMENTS:
        case actionType.GET_SEGMENTS:
            return Object.assign({}, state, { isLoading: true, error: null });

        case actionType.GET_SEGMENTS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                segments: action.payload,
            });

        case actionType.POST_SEGMENT_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                segments: action.payload,
                segmentsToast: {
                    message: "Successfully added a new segment!",
                    type: "success",
                    request: "post"
                }
            });

        case actionType.PUT_SEGMENT_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                segments: action.payload,
                segmentsToast: {
                    message: "Successfully updated segment!",
                    type: "success",
                    request: "put"
                }
            });

        case actionType.DELETE_SEGMENT_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                segments: action.payload,
                segmentsToast: {
                    message: "Successfully deleted segment!",
                    type: "success",
                    request: "delete"
                }
            });

        case actionType.DELETE_SEGMENT_FAILED:
        case actionType.PUT_SEGMENT_FAILED:
        case actionType.POST_SEGMENT_FAILED:
        case actionType.GET_SEGMENTS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                segmentsToast: {
                    message: action.error,
                    type: "failed",
                }
            });

        default:
            return state;
    }
}
