import { takeLatest } from 'redux-saga/effects';
import processAction from "../../../../../../utils/processAction";
import { actionType } from './reducer';

import {
    getPointConversion as getPointConversionService,
    postPointConversion as postPointConversionService,
    putPointConversion as putPointConversionService,
    deletePointConversion as deletePointConversionService,
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getPointConversion({ payload }) {
    yield processAction({
        params: payload,
        service: getPointConversionService,
        success: actionType.GET_POINT_CONVERSION_SUCCESS,
        failed: actionType.GET_POINT_CONVERSION_FAILED
    });
}

function* postPointConversion({ payload }) {
    yield processAction({
        params: payload,
        service: postPointConversionService,
        success: actionType.POST_POINT_CONVERSION_SUCCESS,
        failed: actionType.POST_POINT_CONVERSION_FAILED
    });
}

function* putPointConversion({ payload }) {
    yield processAction({
        params: payload,
        service: putPointConversionService,
        success: actionType.PUT_POINT_CONVERSION_SUCCESS,
        failed: actionType.PUT_POINT_CONVERSION_FAILED
    });
}

function* deletePointConversion({ payload }) {
    yield processAction({
        params: payload,
        service: deletePointConversionService,
        success: actionType.DELETE_POINT_CONVERSION_SUCCESS,
        failed: actionType.DELETE_POINT_CONVERSION_FAILED
    });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetPointConversion() {
    yield takeLatest(actionType.GET_POINT_CONVERSION, getPointConversion)
}

function* watchPostPointConversion() {
    yield takeLatest(actionType.POST_POINT_CONVERSION, postPointConversion)
}

function* watchPutPointConversion() {
    yield takeLatest(actionType.PUT_POINT_CONVERSION, putPointConversion)
}

function* watchDeletePointConversion() {
    yield takeLatest(actionType.DELETE_POINT_CONVERSION, deletePointConversion)
}

export default [
    watchGetPointConversion(),
    watchPostPointConversion(),
    watchPutPointConversion(),
    watchDeletePointConversion(),
];