import {takeLatest} from "redux-saga/effects";
import {actionType} from "./reducer";
import processAction from "@utils/processAction";
import {getTransactionMonitor} from "./service";

function* watchGetTransactionMonitor() {
  yield takeLatest(actionType.GET_TRANSACTION_MONITOR, function* ({payload}) {
    yield processAction({
      params: payload,
      service: getTransactionMonitor,
      success: actionType.GET_TRANSACTION_MONITOR_SUCCESS,
      failed: actionType.GET_TRANSACTION_MONITOR_FAILED,
    });
  });
}

export default [watchGetTransactionMonitor()];
