export const actionType = {

    GET_BRANCH_ANALYTICS: "GET_BRANCH_ANALYTICS",
    GET_BRANCH_ANALYTICS_SUCCESS: "GET_BRANCH_ANALYTICS_SUCCESS",
    GET_BRANCH_ANALYTICS_FAILED: "GET_BRANCH_ANALYTICS_FAILED",

    GET_BRANCH_TRANSACTIONS_GRAPH: "GET_BRANCH_TRANSACTIONS_GRAPH",
    GET_BRANCH_TRANSACTIONS_GRAPH_SUCCESS: "GET_BRANCH_TRANSACTIONS_GRAPH_SUCCESS",
    GET_BRANCH_TRANSACTIONS_GRAPH_FAILED: "GET_BRANCH_TRANSACTIONS_GRAPH_FAILED",

    // NON-API REQUEST
    REMOVE_BRANCH_ANALYTICS_TOAST: "REMOVE_BRANCH_ANALYTICS_TOAST",

}

export const actionCreators = {
    removeBranchAnalyticsToast: () => ({
        type: actionType.REMOVE_BRANCH_ANALYTICS_TOAST
    }),
    getBranchAnalytics: payload => ({
        type: actionType.GET_BRANCH_ANALYTICS, payload
    }),
    getBranchTransactionsGraph: payload => ({
        type: actionType.GET_BRANCH_TRANSACTIONS_GRAPH, payload
    }),
}

const initialState = {
    meta: null,
    isLoading: false,
    error: null,
    branchAnalyticsToast: null,
    branchAnalytics: null,
    branchTransactionsGraph: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.REMOVE_BRANCH_ANALYTICS_TOAST:
            return Object.assign({}, state, { branchAnalyticsToast: null });

        case actionType.GET_BRANCH_TRANSACTIONS_GRAPH:
        case actionType.GET_BRANCH_ANALYTICS:
            return Object.assign({}, state, { isLoading: true, error: null });

        case actionType.GET_BRANCH_TRANSACTIONS_GRAPH_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                branchTransactionsGraph: action.payload,
            });

        case actionType.GET_BRANCH_ANALYTICS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                branchAnalytics: action.payload,
            });

        case actionType.GET_BRANCH_TRANSACTIONS_GRAPH_FAILED:
        case actionType.GET_BRANCH_ANALYTICS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });

        default:
            return state;
    }
}
