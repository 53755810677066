export const actionType = {
  GET_TRANSACTION_MONITOR: "GET_TRANSACTION_MONITOR",
  GET_TRANSACTION_MONITOR_SUCCESS: "GET_TRANSACTION_MONITOR_SUCCESS",
  GET_TRANSACTION_MONITOR_FAILED: "GET_TRANSACTION_MONITOR_FAILED",
  NEXT_PAGE_TRANSACTION_MONITOR: "NEXT_PAGE_TRANSACTION_MONITOR",
};

export const getTransactionMonitor = (payload) => ({
  type: actionType.GET_TRANSACTION_MONITOR,
  payload,
});

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  pagination: {
    per_page: 25,
    current_page: 1,
    total_count: 0,
    total_pages: 0,
    next_page: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_TRANSACTION_MONITOR:
      return Object.assign({}, state, {isLoading: true, error: null});
    case actionType.NEXT_PAGE_TRANSACTION_MONITOR: {
      const currentPage = state.pagination.page;
      return {
        ...state,
        pagination: {current_page: currentPage + 1, ...state.pagination},
      };
    }
    case actionType.GET_TRANSACTION_MONITOR_SUCCESS:
      const {customers: data, pagination} = action.payload;

      return {
        ...state,
        isLoading: false,
        pagination: {...pagination, ...state.pagination},
        data,
      };
    default:
      return state;
  }
};
