export const actionType = {
    GET_CUSTOMER_FEEDBACK: "GET_CUSTOMER_FEEDBACK",
    GET_CUSTOMER_FEEDBACK_SUCCESS: "GET_CUSTOMER_FEEDBACK_SUCCESS",
    GET_CUSTOMER_FEEDBACK_FAILED: "GET_CUSTOMER_FEEDBACK_FAILED",

    POST_CUSTOMER_FEEDBACK: "POST_CUSTOMER_FEEDBACK",
    POST_CUSTOMER_FEEDBACK_SUCCESS: "POST_CUSTOMER_FEEDBACK_SUCCESS",
    POST_CUSTOMER_FEEDBACK_FAILED: "POST_CUSTOMER_FEEDBACK_FAILED",

    PUT_READ_FEEDBACK: "PUT_READ_FEEDBACK",
    PUT_READ_FEEDBACK_SUCCESS: "PUT_READ_FEEDBACK_SUCCESS",
    PUT_READ_FEEDBACK_FAILED: "PUT_READ_FEEDBACK_FAILED",

    REMOVE_CUSTOMER_FEEDBACK_TOAST: "REMOVE_CUSTOMER_FEEDBACK_TOAST",
}

const initialState = {
    data: null,
    meta: null,
    isLoading: false,
    error: null,
    customerFeedbackToast: null,
    customerFeedback: null
};

export const actionCreators = {
    removeCustomerFeedbackToast: payload => ({
        type: actionType.REMOVE_CUSTOMER_FEEDBACK_TOAST
    }),
    getCustomerFeedback: payload => ({
        type: actionType.GET_CUSTOMER_FEEDBACK, payload
    }),
    postCustomerFeedback: payload => ({
        type: actionType.POST_CUSTOMER_FEEDBACK, payload
    }),
    putReadFeedback: payload => ({
        type: actionType.PUT_READ_FEEDBACK, payload
    })
};

export default (state = initialState, action) => {
    switch (action.type) {

        case actionType.REMOVE_CUSTOMER_FEEDBACK_TOAST:
            return Object.assign({}, state, { customerFeedbackToast: null });

        case actionType.PUT_READ_FEEDBACK:
        case actionType.GET_CUSTOMER_FEEDBACK:
        case actionType.POST_CUSTOMER_FEEDBACK:
            return Object.assign({}, state, {
                isLoading: true,
                error: null
            });

        case actionType.GET_CUSTOMER_FEEDBACK_SUCCESS:
            return Object.assign({}, state, {
                customerFeedback: action.payload,
                isLoading: false,
                error: null
            });

        case actionType.POST_CUSTOMER_FEEDBACK_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                customerFeedbackToast: {
                    message: "Successfully added a new feedback!",
                    type: "success",
                    request: "post"
                }
            });

        case actionType.PUT_READ_FEEDBACK_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                customerFeedbackToast: {
                    type: "success",
                    request: "post"
                }
            });


        case actionType.PUT_READ_FEEDBACK_FAILED:
        case actionType.POST_CUSTOMER_FEEDBACK_FAILED:
        case actionType.GET_CUSTOMER_FEEDBACK_FAILED:
            return Object.assign({}, state, {
                error: action.error,
                isLoading: false,
            });

        default:
            return state;
        // break;
    }
}
