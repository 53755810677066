import Loadable from "react-loadable";
import LoadingComponent from "../../commons/loader";

export default [
  {
    path: "/customers",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/customer-list/pages/index"),
      loading: LoadingComponent
    })
  },
  // {
  //   path: "/customers/:id",
  //   exact: false,
  //   auth: true,
  //   component: Loadable({
  //     loader: () => import("./components/CustomerProfileLayout/index"),
  //     loading: LoadingComponent
  //   })
  // },
  {
    path: "/customers/:id/analytics",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/analytics/pages/index"),
      loading: LoadingComponent
    })
  },
  {
    path: "/customers/:id/feedback",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/feedback/pages/index"),
      loading: LoadingComponent
    })
  },
  {
    path: "/customers/:id/transactions",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/transactions/pages/index"),
      loading: LoadingComponent
    })
  }
];
