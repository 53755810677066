import request from "../../utils/api";
const url = "dashboard";

export const getTotals = () => request(`${url}/totals`);
export const getTopBranches = () => request(`${url}/branches`);
export const getTopCustomers = () => request(`${url}/customers`);
export const getProfiles = () => request(`${url}/profiles`);
export const getRangedTotals = () => request(`${url}/ranged-totals`);
export const getCustomersGraph = params => request(`${url}/customers_chart`, { params });
export const getVisits = () => request(`${url}/visits`);
export const getTransactionsGraph = params => request(`${url}/transactions_chart`, { params });
