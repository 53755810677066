import React, { PureComponent } from "react";
import "./styles.css";

export default class PageHeader extends PureComponent {
	render() {
		const {
			title,
			action,
			content,
			extraContent,
			tabActiveKey,
			tabDefaultActiveKey
		} = this.props;

		const activeKeyProps = {};
		if (tabDefaultActiveKey !== undefined) {
			activeKeyProps.defaultActiveKey = tabDefaultActiveKey;
		}
		if (tabActiveKey !== undefined) {
			activeKeyProps.activeKey = tabActiveKey;
		}

		return (
			<div className="pageHeader">
				<div className="detail">
					<div className="main">
						<div className="row">
							{title && <h1 className="title">{title}</h1>}
							{action && <div className="action">{action}</div>}
						</div>
						<div className="row">
							{content && <div className="content">{content}</div>}
							{extraContent && (
								<div className="extraContent">{extraContent}</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
