import { takeLatest} from 'redux-saga/effects';
import processAction from "../../../../utils/processAction";
import { actionType } from './reducer';
import {
    getNotifications as getNotificationsService,
    postNotifications as postNotificationsService,
    putNotifications as putNotificationsService,
    deleteNotifications as deleteNotificationsService,
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getNotifications({ payload }) {
    yield processAction({
        params: payload,
        service: getNotificationsService,
        success: actionType.GET_NOTIFICATIONS_SUCCESS,
        failed: actionType.GET_NOTIFICATIONS_FAILED
    });
}

function* postNotifications({ payload }) {
    yield processAction({
        params: payload,
        service: postNotificationsService,
        success: actionType.POST_NOTIFICATIONS_SUCCESS,
        failed: actionType.POST_NOTIFICATIONS_FAILED
    });
}

function* putNotifications({ payload, id }) {
    yield processAction({
        params: payload,
        service: putNotificationsService,
        success: actionType.PUT_NOTIFICATIONS_SUCCESS,
        failed: actionType.PUT_NOTIFICATIONS_FAILED
    });
}

function* deleteNotifications({ payload }) {
    yield processAction({
        params: payload,
        service: deleteNotificationsService,
        success: actionType.DELETE_NOTIFICATIONS_SUCCESS,
        failed: actionType.DELETE_NOTIFICATIONS_FAILED
    });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetNotifications() {
    yield takeLatest(actionType.GET_NOTIFICATIONS, getNotifications)
}

function* watchPostNotifications() {
    yield takeLatest(actionType.POST_NOTIFICATIONS, postNotifications)
}

function* watchPutNotifications() {
    yield takeLatest(actionType.PUT_NOTIFICATIONS, putNotifications)
}

function* watchDeleteNotifications() {
    yield takeLatest(actionType.DELETE_NOTIFICATIONS, deleteNotifications)
}

export default [
    watchGetNotifications(),
    watchPostNotifications(),
    watchPutNotifications(),
    watchDeleteNotifications(),
];