import { takeLatest } from 'redux-saga/effects';
import processAction from "../../../../../../utils/processAction";
import { actionType } from './reducer';
import {
    getBranchAnalytics as getBranchAnalyticsService,
    getBranchTransactionsGraph as getBranchTransactionsGraphService,
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getBranchAnalytics({ payload }) {
    yield processAction({
        params: payload,
        service: getBranchAnalyticsService,
        success: actionType.GET_BRANCH_ANALYTICS_SUCCESS,
        failed: actionType.GET_BRANCH_ANALYTICS_FAILED
    });
}

function* getBranchTransactionsGraph({ payload }) {
    yield processAction({
        params: payload,
        service: getBranchTransactionsGraphService,
        success: actionType.GET_BRANCH_TRANSACTIONS_GRAPH_SUCCESS,
        failed: actionType.GET_BRANCH_TRANSACTIONS_GRAPH_FAILED
    });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetBranchAnalytics() {
    yield takeLatest(actionType.GET_BRANCH_ANALYTICS, getBranchAnalytics);
}

function* watchGetBranchTransactionsGraph() {
    yield takeLatest(actionType.GET_BRANCH_TRANSACTIONS_GRAPH, getBranchTransactionsGraph);
}

export default [
    watchGetBranchAnalytics(),
    watchGetBranchTransactionsGraph(),
];