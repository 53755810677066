import { takeLatest } from 'redux-saga/effects';
import processAction from "../../../../utils/processAction";
import { actionType } from './reducer';
import {
    getBranchList as getBranchListService,
    deleteBranch as deleteBranchService,
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getBranchList({ payload }) {
    yield processAction({
        params: payload,
        service: getBranchListService,
        success: actionType.GET_BRANCH_LIST_SUCCESS,
        failed: actionType.GET_BRANCH_LIST_FAILED
    })
}

function* deleteBranch({ payload }) {
    yield processAction({
        params: payload,
        service: deleteBranchService,
        success: actionType.DELETE_BRANCH_SUCCESS,
        failed: actionType.DELETE_BRANCH_FAILED
    })
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetBranchList() {
    yield takeLatest(actionType.GET_BRANCH_LIST, getBranchList)
}

function* watchDeleteBranch() {
    yield takeLatest(actionType.DELETE_BRANCH, deleteBranch)
}

export default [
    watchGetBranchList(),
    watchDeleteBranch(),
];