import { takeLatest } from 'redux-saga/effects';

import processAction from "../../../../utils/processAction";
import { actionType } from './reducer';

import {
    getUsers as getUsersService,
    postUser as postUserService,
    deleteUser as deleteUserService,
    putUser as putUserService,
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getUsers({ payload }) {
    yield processAction({
        params: payload,
        service: getUsersService,
        success: actionType.GET_USERS_SUCCESS,
        failed: actionType.GET_USERS_FAILED
    });
}

function* postUser({ payload }) {
    yield processAction({
        params: payload,
        service: postUserService,
        success: actionType.POST_USER_SUCCESS,
        failed: actionType.POST_USER_FAILED
    });
}

function* deleteUser({ payload }) {
    yield processAction({
        params: payload,
        service: deleteUserService,
        success: actionType.DELETE_USER_SUCCESS,
        failed: actionType.DELETE_USER_FAILED
    });
}

function* putUser({ payload }) {
    yield processAction({
        params: payload,
        service: putUserService,
        success: actionType.PUT_USER_SUCCESS,
        failed: actionType.PUT_USER_FAILED
    });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetUsers() {
    yield takeLatest(actionType.GET_USERS, getUsers)
}

function* watchPostUser() {
    yield takeLatest(actionType.POST_USER, postUser)
}

function* watchDeleteUser() {
    yield takeLatest(actionType.DELETE_USER, deleteUser)
}

function* watchPutUser() {
    yield takeLatest(actionType.PUT_USER, putUser)
}

export default [
    watchGetUsers(),
    watchPostUser(),
    watchDeleteUser(),
    watchPutUser(),
];