import React, { Component } from "react";
import { purgeStoredState } from "redux-persist";
import { NavLink, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import storage from "redux-persist/lib/storage";
import { actionCreators as authActionCreators } from "./modules/auth/reducer";

import { Loader } from "./commons"
import { deleteToken } from "./utils/token";
import bladeLogo from "./images/blade-logo.png";
import { Layout, Menu, Avatar, Dropdown, Icon } from "antd";
import "./App.css";

const { Content, Header } = Layout;

const menu = (
	<Menu>
		<Menu.Item>
			<NavLink to="/logout">
				<Icon type="logout" /> Logout
			</NavLink>
		</Menu.Item>
	</Menu>
);

class App extends Component {
	state = { logoutVisible: false };

	onCollapse = collapsed => this.setState({ collapsed });

	onOk = () => {
		deleteToken();
		purgeStoredState({ key: "root", storage });
		purgeStoredState({ key: "auth", storage });
		window.location.href = "/";
	};

	hideModal = () => this.setState({ logoutVisible: false });

	renderPrivatePage(user) {
		const initial = user && user.user_name && user.user_name[0];

		return (
			<Layout style={{ minHeight: "100vh" }} className="dashboard">
				<Header>
					<Menu
						mode="horizontal"
						defaultSelectedKeys={["/dashboard"]}
						selectedKeys={[window.location.pathname]}
						className="top-menu"
					>
						<Menu.Item>
							<NavLink to="/">
								<img src={bladeLogo} alt="" />
							</NavLink>
						</Menu.Item>
						{
							user.user_type !== "admin_1"
							&& <Menu.Item key="/dashboard">
								<NavLink to="/dashboard" className="nav-text">
									<span>Dashboard</span>
								</NavLink>
							</Menu.Item>
						}
						<Menu.Item key="/customers">
							<NavLink to="/customers" className="nav-text">
								<span>Customers</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="/transactions">
							<NavLink to="/transactions" className="nav-text">
								<span>Transactions</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="/transaction-monitor">
							<NavLink to="/transaction-monitor" className="nav-text">
								<span>Transaction Monitor</span>
							</NavLink>
						</Menu.Item>
						{
							user.user_type !== "admin_1"
							&& <Menu.Item key="/branches">
								<NavLink to="/branches" className="nav-text">
									<span>Branch</span>
								</NavLink>
							</Menu.Item>
						}
						{
							user.user_type !== "admin_1"
							&& <Menu.Item key="/customer-engagements">
								<NavLink to="/customer-engagements" className="nav-text">
									<span>Customer Engagement</span>
								</NavLink>
							</Menu.Item>
						}
						<Menu.Item style={{ float: "right" }}>
							<Dropdown overlay={menu}>
								<div>
									<Avatar style={{ backgroundColor: '#e9e9e9' }}>
										<strong style={{ fontSize: "15px", color: "#000" }}>{initial}</strong>
									</Avatar>
									<span className="header-username">{user && user.user_name} <Icon style={{ fontSize: "10px" }} type="down" /></span>
								</div>
							</Dropdown>
						</Menu.Item>
						{
							user.user_type !== "admin_1"
							&& <Menu.Item key="/settings/users" style={{ float: "right" }}>
								<NavLink to="/settings/users" className="nav-text">
									<span>Settings</span>
								</NavLink>
							</Menu.Item>
						}
					</Menu>
				</Header>
				<Layout>
					<Content style={{ overflowX: "hidden" }}>
						{this.props.children}
					</Content>
				</Layout>
			</Layout>
		);
	}

	renderLoginPage() {
		return (
			<div className="app">
				<main className="main">
					<Layout id="auth">
						<Content>{this.props.children}</Content>
					</Layout>
				</main>
			</div>
		);
	}

	render() {
		const { isAuthenticated, isLoading, user } = this.props;

		if (isAuthenticated && user && user.user_type !== "branch") {
			if (isLoading) {
				return <Loader isLoading={isLoading} />;
			} else {
				return this.renderPrivatePage(user);
			}
		} else {
			return this.renderLoginPage();
		}

	}
}

const mapStateToProps = (state, ownState) => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		isLoading: state.auth.isLoading,
		user: state.auth.user
	}
};

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(authActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
