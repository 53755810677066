export const actionType = {
  GET_CUSTOMER_LIST: "GET_CUSTOMER_LIST",
  GET_CUSTOMER_LIST_SUCCESS: "GET_CUSTOMER_LIST_SUCCESS",
  GET_CUSTOMER_LIST_FAILED: "GET_CUSTOMER_LIST_FAILED",

  GET_CUSTOMER_DETAIL: "GET_CUSTOMER_DETAIL",
  GET_CUSTOMER_DETAIL_SUCCESS: "GET_CUSTOMER_DETAIL_SUCCESS",
  GET_CUSTOMER_DETAIL_FAILED: "GET_CUSTOMER_DETAIL_FAILED",

  PUT_CUSTOMER_ACTIVATE: "PUT_CUSTOMER_ACTIVATE",
  PUT_CUSTOMER_ACTIVATE_SUCCESS: "PUT_CUSTOMER_ACTIVATE_SUCCESS",
  PUT_CUSTOMER_ACTIVATE_FAILED: "PUT_CUSTOMER_ACTIVATE_FAILED",

  PUT_CUSTOMER: "PUT_CUSTOMER",
  PUT_CUSTOMER_SUCCESS: "PUT_CUSTOMER_SUCCESS",
  PUT_CUSTOMER_FAILED: "PUT_CUSTOMER_FAILED",

  DELETE_CUSTOMER: "DELETE_CUSTOMER",
  DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_FAILED: "DELETE_CUSTOMER_FAILED",

  SUSPEND_CUSTOMER: "SUSPEND_CUSTOMER",
  SUSPEND_CUSTOMER_SUCCESS: "SUSPEND_CUSTOMER_SUCCESS",
  SUSPEND_CUSTOMER_FAILED: "SUSPEND_CUSTOMER_FAILED",

  // NON-API REQUEST
  REMOVE_CUSTOMER_TOAST: "REMOVE_CUSTOMER_TOAST",
};

export const actionCreators = {
  removeCustomerToast: () => ({
    type: actionType.REMOVE_CUSTOMER_TOAST,
  }),
  getCustomerList: (payload) => ({
    type: actionType.GET_CUSTOMER_LIST,
    payload,
  }),
  getCustomerDetail: (params) => ({
    type: actionType.GET_CUSTOMER_DETAIL,
    params,
  }),
  putCustomerActivate: (payload) => ({
    type: actionType.PUT_CUSTOMER_ACTIVATE,
    payload,
  }),
  putCustomer: (payload) => ({
    type: actionType.PUT_CUSTOMER,
    payload,
  }),
  deleteCustomer: (payload) => ({
    type: actionType.DELETE_CUSTOMER,
    payload,
  }),
  suspendCustomer: (payload) => ({
    type: actionType.SUSPEND_CUSTOMER,
    payload,
  }),
};

const initialState = {
  meta: null,
  isLoading: false,
  error: null,
  customerToast: null,
  customers: [],
  customerDetail: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.REMOVE_CUSTOMER_TOAST:
      return Object.assign({}, state, { customerToast: null });

    case actionType.SUSPEND_CUSTOMER:
    case actionType.DELETE_CUSTOMER:
    case actionType.PUT_CUSTOMER:
    case actionType.GET_CUSTOMER_LIST:
    case actionType.GET_CUSTOMER_DETAIL:
    case actionType.PUT_CUSTOMER_ACTIVATE:
      return Object.assign({}, state, { isLoading: true, error: null });

    case actionType.GET_CUSTOMER_LIST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        customers: action.payload,
      });
    case actionType.GET_CUSTOMER_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        customerDetail: action.payload.customer,
      });

    case actionType.PUT_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        customerToast: {
          message: "Successfully updated customer",
          type: "success",
          request: "put",
        },
      });
    case actionType.PUT_CUSTOMER_ACTIVATE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        customerToast: {
          message: "Customer Activated!",
          type: "success",
          request: "put",
        },
      });
    case actionType.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        customerToast: {
          message: action.payload.message,
          type: "success",
          request: "delete",
        },
      };
    case actionType.DELETE_CUSTOMER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        customerToast: {
          message: "There's something wrong in the server.",
          type: "failed",
          request: "delete",
        },
      };
    case actionType.SUSPEND_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        customerToast: {
          message: action.payload.message,
          type: "success",
          request: "suspend",
        },
      };
    case actionType.SUSPEND_CUSTOMER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        customerToast: {
          message: "There's something wrong in the server.",
          type: "failed",
          request: "suspend",
        },
      };

    case actionType.PUT_CUSTOMER_FAILED:
    case actionType.PUT_CUSTOMER_ACTIVATE_FAILED:
    case actionType.GET_CUSTOMER_LIST_FAILED:
    case actionType.GET_CUSTOMER_DETAIL_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        customerToast: {
          message: action && action.message,
          type: "failed",
        },
      });

    default:
      return state;
  }
};
