import { takeLatest } from 'redux-saga/effects';

import processAction from "../../utils/processAction";
import { actionTypes } from './reducer';

import {
    getTotals as getTotalsService,
    getTopBranches as getTopBranchesService,
    getTopCustomers as getTopCustomersService,
    getProfiles as getProfilesService,
    getRangedTotals as getRangedTotalsService,
    getCustomersGraph as getCustomersGraphService,
    getVisits as getVisitsService,
    getTransactionsGraph as getTransactionsGraphService,
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getTotals({ payload }) {
    yield processAction({
        params: payload,
        service: getTotalsService,
        success: actionTypes.GET_TOTALS_SUCCESS,
        failed: actionTypes.GET_TOTALS_FAILED
    });
}

function* getTopBranches({ payload }) {
    yield processAction({
        params: payload,
        service: getTopBranchesService,
        success: actionTypes.GET_TOP_BRANCHES_SUCCESS,
        failed: actionTypes.GET_TOP_BRANCHES_FAILED
    });
}

function* getTopCustomers({ payload }) {
    yield processAction({
        params: payload,
        service: getTopCustomersService,
        success: actionTypes.GET_TOP_CUSTOMERS_SUCCESS,
        failed: actionTypes.GET_TOP_CUSTOMERS_FAILED
    });
}

function* getProfiles({ payload }) {
    yield processAction({
        params: payload,
        service: getProfilesService,
        success: actionTypes.GET_PROFILES_SUCCESS,
        failed: actionTypes.GET_PROFILES_FAILED
    });
}

function* getRangedTotals({ payload }) {
    yield processAction({
        params: payload,
        service: getRangedTotalsService,
        success: actionTypes.GET_RANGED_TOTALS_SUCCESS,
        failed: actionTypes.GET_RANGED_TOTALS_FAILED
    });
}

function* getCustomersGraph({ payload }) {
    yield processAction({
        params: payload,
        service: getCustomersGraphService,
        success: actionTypes.GET_CUSTOMERS_GRAPH_SUCCESS,
        failed: actionTypes.GET_CUSTOMERS_GRAPH_FAILED
    });
}

function* getVisits({ payload }) {
    yield processAction({
        params: payload,
        service: getVisitsService,
        success: actionTypes.GET_VISITS_SUCCESS,
        failed: actionTypes.GET_VISITS_FAILED
    });
}

function* getTransactionsGraph({ payload }) {
    yield processAction({
        params: payload,
        service: getTransactionsGraphService,
        success: actionTypes.GET_TRANSACTIONS_GRAPH_SUCCESS,
        failed: actionTypes.GET_TRANSACTIONS_GRAPH_FAILED
    });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetTotals() {
    yield takeLatest(actionTypes.GET_TOTALS, getTotals);
}

function* watchGetTopBranches() {
    yield takeLatest(actionTypes.GET_TOP_BRANCHES, getTopBranches);
}

function* watchGetTopCustomers() {
    yield takeLatest(actionTypes.GET_TOP_CUSTOMERS, getTopCustomers);
}

function* watchGetProfiles() {
    yield takeLatest(actionTypes.GET_PROFILES, getProfiles);
}

function* watchGetRangedTotals() {
    yield takeLatest(actionTypes.GET_RANGED_TOTALS, getRangedTotals);
}

function* watchGetCustomersGraph() {
    yield takeLatest(actionTypes.GET_CUSTOMERS_GRAPH, getCustomersGraph);
}

function* watchGetVisits() {
    yield takeLatest(actionTypes.GET_VISITS, getVisits);
}

function* watchGetTransactionsGraph() {
    yield takeLatest(actionTypes.GET_TRANSACTIONS_GRAPH, getTransactionsGraph);
}

export default [
    watchGetTotals(),
    watchGetTopBranches(),
    watchGetTopCustomers(),
    watchGetProfiles(),
    watchGetRangedTotals(),
    watchGetCustomersGraph(),
    watchGetVisits(),
    watchGetTransactionsGraph()
];