import request from "../../../../../../utils/api";

let mechanics = "program_mechanics";

export const getMechanics = body => request(`${mechanics}?page=${body.paginate}`);
export const postMechanics = body => request(`${mechanics}`, { method: 'post', body });
export const putMechanics = (body, id) => request(`${mechanics}/${body.get('program_mechanic[id]')}`, { method: 'put', body });
export const putMechanicsStatus = body => request(`${mechanics}/${body.program_mechanic.id}`, { method: 'put', body });
export const deleteMechanics = body => request(`${mechanics}/${body.program_mechanic.id}`, { method: 'delete' });

