import React, { Component } from "react";
import Error404 from "../../images/404.png";
import ErrorLost from "../../images/lost.png";
import { Row, Col, Button, Icon, Avatar } from "antd";
import './index.css';

export default class ErrorPage extends Component {
    render() {
        const { url, btnTitle, history } = this.props;
        return (
            <Row>
                <Col span={6} offset={3}>
                    <Row type="flex" justify="end">
                        <Avatar
                            src={ErrorLost}
                            shape="square"
                            className="error-lost"
                        />
                    </Row>
                </Col>
                <Col span={15}>
                    <Row type="flex" justify="center">
                        <Avatar
                            src={Error404}
                            shape="square"
                            className="error-picture"
                        />
                    </Row>
                    <Row type="flex" justify="center">
                        <h1 className="error-title">SORRY, PAGE NOT FOUND!</h1>
                    </Row>
                    <Row type="flex" justify="center">
                        <Button className="error-btn" onClick={() => history.push(`${url ? url : "/"}`)}> <Icon type="rollback" /> {btnTitle ? btnTitle : "Go back"}</Button>
                    </Row>
                </Col>
            </Row>
        )
    }
}; 