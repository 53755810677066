import { takeLatest } from 'redux-saga/effects';
import processAction from "../../../../utils/processAction";
import { actionType } from './reducer';
import {
    getCustomerFeedback as getCustomerFeedbackService,
    postCustomerFeedback as postCustomerFeedbackService,
    putReadFeedback as putReadFeedbackService
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getCustomerFeedback({ payload }) {
    yield processAction({
        params: payload,
        service: getCustomerFeedbackService,
        success: actionType.GET_CUSTOMER_FEEDBACK_SUCCESS,
        failed: actionType.GET_CUSTOMER__FEEDBACK_FAILED
    });
}

function* postCustomerFeedback({ payload }) {
    yield processAction({
        params: payload,
        service: postCustomerFeedbackService,
        success: actionType.POST_CUSTOMER_FEEDBACK_SUCCESS,
        failed: actionType.POST_CUSTOMER_FEEDBACK_FAILED
    });
}

function* putReadFeedback({ payload }) {
    yield processAction({
        params: payload,
        service: putReadFeedbackService,
        success: actionType.PUT_READ_FEEDBACK_SUCCESS,
        failed: actionType.PUT_READ_FEEDBACK_FAILED
    });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetCustomerFeedback() {
    yield takeLatest(actionType.GET_CUSTOMER_FEEDBACK, getCustomerFeedback)
}

function* watchPostCustomerFeedback() {
    yield takeLatest(actionType.POST_CUSTOMER_FEEDBACK, postCustomerFeedback)
}

function* watchPutReadFeedback() {
    yield takeLatest(actionType.PUT_READ_FEEDBACK, putReadFeedback)
}

export default [
    watchGetCustomerFeedback(),
    watchPostCustomerFeedback(),
    watchPutReadFeedback()
];