import Loadable from "react-loadable";
import LoadingComponent from "../../commons/loader";

export default [
    {
        path: "/customer-engagements",
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import("./pages/notification/pages/index"),
            loading: LoadingComponent
        })
    },
    {
        path: "/customer-engagements/events",
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import("./pages/events/pages/index"),
            loading: LoadingComponent
        })
    },
    {
        path: "/customer-engagements/feedback",
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import("./pages/feedback/pages/index"),
            loading: LoadingComponent
        })
    },
    {
        path: "/customer-engagements/segment",
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import("./pages/segment/pages/index"),
            loading: LoadingComponent
        })
    }
];
