export const actionType = {

    GET_BRANCH_TRANSACTIONS: "GET_BRANCH_TRANSACTIONS",
    GET_BRANCH_TRANSACTIONS_SUCCESS: "GET_BRANCH_TRANSACTIONS_SUCCESS",
    GET_BRANCH_TRANSACTIONS_FAILED: "GET_BRANCH_TRANSACTIONS_FAILED",

    // NON-API REQUEST
    REMOVE_BRANCH_TRANSACTIONS_TOAST: "REMOVE_BRANCH_TRANSACTIONS_TOAST",

}

export const actionCreators = {
    removeBranchTransactionsToast: () => ({
        type: actionType.REMOVE_BRANCH_TRANSACTIONS_TOAST
    }),
    getBranchTransactions: payload => ({
        type: actionType.GET_BRANCH_TRANSACTIONS, payload
    }),
}

const initialState = {
    meta: null,
    isLoading: false,
    error: null,
    branchTransactionsToast: null,
    branchTransactions: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.REMOVE_BRANCH_TRANSACTIONS_TOAST:
            return Object.assign({}, state, { branchTransactionsToast: null });

        case actionType.GET_BRANCH_TRANSACTIONS:
            return Object.assign({}, state, { isLoading: true, error: null });

        case actionType.GET_BRANCH_TRANSACTIONS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                branchTransactions: action.payload,
            });

        case actionType.GET_BRANCH_TRANSACTIONS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });

        default:
            return state;
    }
}
