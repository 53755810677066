import request from '@utils/api';

const url = 'transactions';

export const getTransactions = params => request(url, { params });
export const postTransaction = body => request(url, { method: 'post', body });
export const putTransaction = body =>
  request(`${url}/${body.transaction.id}`, { method: 'put', body });
export const getExportTransactions = params => request(`${url}/download_csv`, { params });
export const deleteTransactions = body =>
  request(`${url}/delete_records`, { body, method: 'post' });
