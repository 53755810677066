import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import auth from "../modules/auth/reducer";
import dashboard from "../modules/dashboard/reducer";
import customers from '../modules/customer/pages/customer-list/reducer';
import customerAnalytics from '../modules/customer/pages/analytics/reducer';
import customerFeedback from '../modules/customer/pages/feedback/reducer';
import customerTransactions from '../modules/customer/pages/transactions/reducer';
import transactions from '../modules/transactions/reducer';
import transactionMonitor from '../modules/transaction-monitor/reducer';
import users from "../modules/settings/pages/users/reducer";
import branches from "../modules/branch/pages/list/reducer";
import manageBranches from "../modules/branch/pages/manage-branches/pages/reducer";
import branchAnalytics from "../modules/branch/pages/profile/pages/analytics/reducer";
import branchTransactions from "../modules/branch/pages/profile/pages/transactions/reducer";
import events from "../modules/engagements/pages/events/reducer";
import segments from "../modules/engagements/pages/segment/reducer";
import notifications from "../modules/engagements/pages/notification/reducer";
import feedbacks from "../modules/engagements/pages/feedback/reducer";
import mechanics from "../modules/settings/pages/mechanics/pages/programMechanics/reducer";
import pointConversion from "../modules/settings/pages/mechanics/pages/pointConversion/reducer";
import achievements from "../modules/settings/pages/mechanics/pages/achievements/reducer";


const authPersistConfig = {
	key: "auth",
	storage
};

export default combineReducers({
	// Authentication
	auth: persistReducer(authPersistConfig, auth),

	// Dashboard
	dashboard,

	// Branch
	branches,
	manageBranches,
	branchAnalytics,
	branchTransactions,

	// Customer
	customers,
	customerAnalytics,
	customerFeedback,
	customerTransactions,

	// transactions
	transactions,
	transactionMonitor,

	// Customer Engagement
	events,
	segments,
	notifications,
	feedbacks,

	// Settings
	users,
	mechanics,
	pointConversion,
	achievements
});
