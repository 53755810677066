import { takeLatest } from 'redux-saga/effects';
import processAction from "../../../../../../utils/processAction";
import { actionType } from './reducer';
import {
    getBranchTransactions as getBranchTransactionsService,
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getBranchTransactions({ payload }) {
    yield processAction({
        params: payload,
        service: getBranchTransactionsService,
        success: actionType.GET_BRANCH_TRANSACTIONS_SUCCESS,
        failed: actionType.GET_BRANCH_TRANSACTIONS_FAILED
    });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetBranchTransactions() {
    yield takeLatest(actionType.GET_BRANCH_TRANSACTIONS, getBranchTransactions);
}

export default [
    watchGetBranchTransactions(),
];