import { takeLatest } from 'redux-saga/effects';
import processAction from "../../../../utils/processAction";
import { actionType } from './reducer';

import {
    getEvents as getEventsService,
    postEvents as postEventsService,
    putEvents as putEventsService,
    deleteEvents as deleteEventsService,
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getEvents({ payload }) {
    yield processAction({
        params: payload,
        service: getEventsService,
        success: actionType.GET_EVENTS_SUCCESS,
        failed: actionType.GET_EVENTS_FAILED
    });
}

function* postEvents({ payload }) {
    yield processAction({
        params: payload,
        service: postEventsService,
        success: actionType.POST_EVENTS_SUCCESS,
        failed: actionType.POST_EVENTS_FAILED
    });
}

function* putEvents({ payload }) {
    yield processAction({
        params: payload,
        service: putEventsService,
        success: actionType.PUT_EVENTS_SUCCESS,
        failed: actionType.PUT_EVENTS_FAILED
    });
}

function* deleteEvents({ payload }) {
    yield processAction({
        params: payload,
        service: deleteEventsService,
        success: actionType.DELETE_EVENTS_SUCCESS,
        failed: actionType.DELETE_EVENTS_FAILED
    });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetEvents() {
    yield takeLatest(actionType.GET_EVENTS, getEvents)
}

function* watchPostEvents() {
    yield takeLatest(actionType.POST_EVENTS, postEvents)
}

function* watchPutEvents() {
    yield takeLatest(actionType.PUT_EVENTS, putEvents)
}

function* watchDeleteEvents() {
    yield takeLatest(actionType.DELETE_EVENTS, deleteEvents)
}

export default [
    watchGetEvents(),
    watchPostEvents(),
    watchPutEvents(),
    watchDeleteEvents(),
];