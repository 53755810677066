import React, { Component } from "react";
import moment from "moment";
import YearPicker from "react-year-picker";
import { Radio, DatePicker, Col, Row, Typography, Select } from 'antd';
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import './index.css';
const Option = Select.Option;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const gutter = { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 };

export default class IntervalChart extends Component {

    state = {
        month: {
            1: 0,
            2: 12
        },
        isOpen: false
    }

    handleOpenChange = (status) => {
        if (status) {
            this.setState({ isOpen: true })
        } else {
            this.setState({ isOpen: false })
        }
    }

    onChangeDatePicker = (value, keys) => {
        let month = this.state.month;
        let data_1 = moment(value[0]).months();
        let data_2 = moment(value[1]).months();
        month[1] = data_1;
        month[2] = data_2 + 1;

        this.setState({ month });

        this.props.onChangeRangeDate(value, "range_date")
    }

    onChangeYear = (e) => {
        const year = moment(e._d).format("YYYY");
        this.setState({ isOpen: false })
        this.props.year(year);
    }

    mosGenerator = (month) => {
        month[0] = "Jan";
        month[1] = "Feb";
        month[2] = "Mar";
        month[3] = "Apr";
        month[4] = "May";
        month[5] = "Jun";
        month[6] = "Jul";
        month[7] = "Aug";
        month[8] = "Sept";
        month[9] = "Oct";
        month[10] = "Nov";
        month[11] = "Dec";
    }



    render() {
        const { transactions, position, height, columns, date } = this.props;

        let modified_transactions = [];

        if (transactions && transactions.length !== 0) {
            let data = transactions && transactions.data;
            let month = [];
            this.mosGenerator(month);
            let new_month = [...month.slice(this.state.month[1], this.state.month[2]), ...month.slice(this.state.month[1], this.state.month[2])]
            let dbarr = {};
            for (let i = 0; i < data.length; i++) { dbarr[data[i].title] = data[i].transactions }
            modified_transactions = new_month.map(mo => { return { "title": `${mo}`, "transactions": !dbarr[mo] ? 0 : dbarr[mo] } })
        }

        return (
            <div>
                <Row type="flex" justify="space-between" gutter={gutter} style={{ margin: "24px 0" }}>
                    <Col xs={24} lg={6}>
                        <Title level={4}>Transaction Amount</Title>
                    </Col>
                    <Col>
                        {/* <RangePicker
                            allowClear={false}
                            //disabledDate={(e, i) => this.disabledDate(e, i, "HELLO")}
                            value={[
                                moment(date["transaction_date[from]"], "LL"),
                                moment(date["transaction_date[to]"], "LL")
                            ]}
                            format={"LL"}
                            className="fit"
                            onChange={(e, i) => this.onChangeDatePicker(i, "range_date")}
                        /> */}
                        <DatePicker
                            allowClear={false}
                            format={"YYYY"}
                            value={moment(date["transaction_date[from]"])}
                            mode="year"
                            onPanelChange={(v) => { this.onChangeYear(v) }}
                            open={this.state.isOpen}
                            onOpenChange={this.handleOpenChange}
                        />
                        {/* <YearPicker onChange={this.onChangeYear} /> */}
                    </Col>
                    {/* <Col xs={24} lg={6}>
                        <Radio.Group
                            className="fit chart-scope"
                            value={date.group_by}
                            buttonStyle="solid"
                            onChange={(e) => this.props.onChangeDate(e.target.value, "group_by")}
                        >
                            <Radio.Button value="group_by_day">Day</Radio.Button>
                            <Radio.Button value="group_by_week">Week</Radio.Button>
                            <Radio.Button value="group_by_month">Month</Radio.Button>
                        </Radio.Group>
                    </Col> */}
                </Row>
                {
                    transactions && transactions.data.length === 0
                        ? <Row type="flex" justify="center">
                            No Available Transactions
                        </Row>
                        : <Chart
                            forceFit
                            height={height}
                            padding="auto"
                            data={modified_transactions}
                            scale={columns}
                            style={{ fontSize: '12' }}
                        >
                            <Tooltip />
                            <Axis name="title" />
                            <Axis name="transactions" />


                            <Geom
                                type="interval"
                                position={position}
                            />
                        </Chart>
                }
            </div>
        );
    }
}
