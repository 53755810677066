import { takeLatest } from "redux-saga/effects";

import processAction from "../../../../utils/processAction";
import { actionType } from "./reducer";

import {
  getCustomerList as getCustomerListService,
  getCustomerDetail as getCustomerDetailService,
  putCustomerActivate as putCustomerActivateService,
  putCustomer as putCustomerService,
  deleteCustomer,
  suspendCustomer,
} from "./service";

/************************/
/******* WORKERS ********/
/************************/

function* getCustomerList({ payload }) {
  yield processAction({
    params: payload,
    service: getCustomerListService,
    success: actionType.GET_CUSTOMER_LIST_SUCCESS,
    failed: actionType.GET_CUSTOMER_LIST_FAILED,
  });
}

function* getCustomerDetail({ params }) {
  yield processAction({
    params,
    service: getCustomerDetailService,
    success: actionType.GET_CUSTOMER_DETAIL_SUCCESS,
    failed: actionType.GET_CUSTOMER_DETAIL_FAILED,
  });
}
function* putCustomerActivate({ payload }) {
  yield processAction({
    params: payload,
    service: putCustomerActivateService,
    success: actionType.PUT_CUSTOMER_ACTIVATE_SUCCESS,
    failed: actionType.PUT_CUSTOMER_ACTIVATE_FAILED,
  });
}
function* putCustomer({ payload }) {
  yield processAction({
    params: payload,
    service: putCustomerService,
    success: actionType.PUT_CUSTOMER_SUCCESS,
    failed: actionType.PUT_CUSTOMER_FAILED,
  });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetCustomerList() {
  yield takeLatest(actionType.GET_CUSTOMER_LIST, getCustomerList);
}
function* watchGetCustomerDetail() {
  yield takeLatest(actionType.GET_CUSTOMER_DETAIL, getCustomerDetail);
}
function* watchPutCustomerActivate() {
  yield takeLatest(actionType.PUT_CUSTOMER_ACTIVATE, putCustomerActivate);
}
function* watchPutCustomer() {
  yield takeLatest(actionType.PUT_CUSTOMER, putCustomer);
}

function* watchDeleteCustomer() {
  yield takeLatest(actionType.DELETE_CUSTOMER, function* ({ payload }) {
    yield processAction({
      params: { id: payload },
      service: deleteCustomer,
      success: actionType.DELETE_CUSTOMER_SUCCESS,
      failed: actionType.DELETE_CUSTOMER_FAILED,
    });
  });
}

function* watchSuspendCustomer() {
  yield takeLatest(actionType.SUSPEND_CUSTOMER, function* ({ payload }) {
    yield processAction({
      params: { customerId: payload },
      service: suspendCustomer,
      success: actionType.SUSPEND_CUSTOMER_SUCCESS,
      failed: actionType.SUSPEND_CUSTOMER_FAILED,
    });
  });
}

export default [
  watchGetCustomerList(),
  watchGetCustomerDetail(),
  watchPutCustomerActivate(),
  watchPutCustomer(),
  watchDeleteCustomer(),
  watchSuspendCustomer(),
];
