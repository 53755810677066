import { takeLatest } from 'redux-saga/effects';

import processAction from "../../../../utils/processAction";
import { actionType } from './reducer';

import {
    getCustomerAnalytics as getCustomerAnalyticsService,
    getCustomerTransactionsGraph as getCustomerTransactionsGraphService,
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getCustomerAnalytics({ payload }) {
    yield processAction({
        params: payload,
        service: getCustomerAnalyticsService,
        success: actionType.GET_CUSTOMER_ANALYTICS_SUCCESS,
        failed: actionType.GET_CUSTOMER_ANALYTICS_FAILED
    })
}

function* getCustomerTransactionsGraph({ payload }) {
    yield processAction({
        params: payload,
        service: getCustomerTransactionsGraphService,
        success: actionType.GET_CUSTOMER_TRANSACTIONS_GRAPH_SUCCESS,
        failed: actionType.GET_CUSTOMER_TRANSACTIONS_GRAPH_FAILED
    })
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetCustomerAnalytics() {
    yield takeLatest(actionType.GET_CUSTOMER_ANALYTICS, getCustomerAnalytics);
}

function* watchGetCustomerTransactionsGraph() {
    yield takeLatest(actionType.GET_CUSTOMER_TRANSACTIONS_GRAPH, getCustomerTransactionsGraph);
}

export default [
    watchGetCustomerAnalytics(),
    watchGetCustomerTransactionsGraph(),
];