export const actionType = {

    GET_EVENTS: "GET_EVENTS",
    GET_EVENTS_SUCCESS: "GET_EVENTS_SUCCESS",
    GET_EVENTS_FAILED: "GET_EVENTS_FAILED",

    POST_EVENTS: "POST_EVENTS",
    POST_EVENTS_SUCCESS: "POST_EVENTS_SUCCESS",
    POST_EVENTS_FAILED: "POST_EVENTS_FAILED",

    PUT_EVENTS: "PUT_EVENTS",
    PUT_EVENTS_SUCCESS: "PUT_EVENTS_SUCCESS",
    PUT_EVENTS_FAILED: "PUT_EVENTS_FAILED",

    DELETE_EVENTS: "DELETE_EVENTS",
    DELETE_EVENTS_SUCCESS: "DELETE_EVENTS_SUCCESS",
    DELETE_EVENTS_FAILED: "DELETE_EVENTS_FAILED",

    // NON-API REQUEST
    REMOVE_EVENTS_TOAST: "REMOVE_EVENTS_TOAST",

}

export const actionCreators = {
    removeEventsToast: () => ({
        type: actionType.REMOVE_EVENTS_TOAST
    }),
    getEvents: payload => ({
        type: actionType.GET_EVENTS, payload
    }),
    postEvents: payload => ({
        type: actionType.POST_EVENTS, payload
    }),
    putEvents: payload => ({
        type: actionType.PUT_EVENTS, payload
    }),
    deleteEvents: payload => ({
        type: actionType.DELETE_EVENTS, payload
    }),
}

const initialState = {
    meta: null,
    isLoading: false,
    error: null,
    eventsToast: null,
    events: null,
}


export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.REMOVE_EVENTS_TOAST:
            return Object.assign({}, state, { eventsToast: null });

        case actionType.GET_EVENTS:
        case actionType.POST_EVENTS:
        case actionType.PUT_EVENTS:
        case actionType.DELETE_EVENTS:
            return Object.assign({}, state, { isLoading: true, error: null });

        case actionType.GET_EVENTS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                events: action.payload,
            });

        case actionType.POST_EVENTS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                eventsToast: {
                    message: 'Successfully Added New Event!',
                    type: "success",
                    request: "post"
                }
            });

        case actionType.PUT_EVENTS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                eventsToast: {
                    message: 'Successfully Updated Event!',
                    type: "success",
                    request: "put"
                }
            });

        case actionType.DELETE_EVENTS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                eventsToast: {
                    message: 'Successfully Deleted Event!',
                    type: "success",
                    request: "delete"
                }
            });

        case actionType.GET_EVENTS_FAILED:
        case actionType.POST_EVENTS_FAILED:
        case actionType.PUT_EVENTS_FAILED:
        case actionType.DELETE_EVENTS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                eventsToast: {
                    message: action.error,
                    type: "failed",
                    request: "post"
                }
            });

        default:
            return state;
    }
}
