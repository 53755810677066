import { takeLatest } from 'redux-saga/effects';

import processAction from "../../../../utils/processAction";
import { actionType } from './reducer';

import {
    getCustomerTransaction as getCustomerTransactionService,
    updateCustomerTransaction as updateCustomerTransactionService
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getCustomerTransaction({ payload }) {
    yield processAction({
        params: payload,
        service: getCustomerTransactionService,
        success: actionType.GET_CUSTOMER_TRANSACTION_SUCCESS,
        failed: actionType.GET_CUSTOMER_TRANSACTION_FAILED
    })
}

function* updateCustomerTransaction({ payload }) {
    yield processAction({
        params: payload,
        service: updateCustomerTransactionService,
        success: actionType.UPDATE_CUSTOMER_TRANSACTION_SUCCESS,
        failed: actionType.UPDATE_CUSTOMER_TRANSACTION_FAILED
    })
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetCustomerTransaction() {
    yield takeLatest(actionType.GET_CUSTOMER_TRANSACTION, getCustomerTransaction)
}
function* watchUpdateCustomerTransaction() {
    yield takeLatest(actionType.UPDATE_CUSTOMER_TRANSACTION, updateCustomerTransaction)
}
export default [
    watchGetCustomerTransaction(),
    watchUpdateCustomerTransaction()
];