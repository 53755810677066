import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import './index.css';

const ModalComponent = ({
  visible,
  onCancel,
  title,
  content,
  footer,
  onOk,
  className,
  destroyOnClose,
  ...restProps
}) => (
  <Modal
    className={`add-modal ${className}`}
    title={title}
    visible={visible}
    onCancel={onCancel}
    onOk={onOk}
    footer={footer}
    destroyOnClose={destroyOnClose}
    {...restProps}>
    <div>{content}</div>
  </Modal>
);

ModalComponent.propTypes = {
  footer: PropTypes.element,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

ModalComponent.defaultProps = {
  footer: null,
};

export default ModalComponent;
