import request from "../../../../../../utils/api";

let url = "achievements";

export const getAchievements = body => request(`${url}?page=${body.paginate}`);
export const postAchievements = body => request(`${url}`, { method: 'post', body });
export const putAchievements = body => request(`${url}/${body.achievement.id}`, { method: 'put', body });
export const deleteAchievements = body => request(`${url}/${body}`, { method: 'delete' });


