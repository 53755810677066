import { takeLatest } from 'redux-saga/effects';
import processAction from "../../../../../../utils/processAction";
import { actionType } from './reducer';

import {
    getAchievements as getAchievementsService,
    postAchievements as postAchievementsService,
    putAchievements as putAchievementsService,
    deleteAchievements as deleteAchievementsService,
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getAchievements({ payload }) {
    yield processAction({
        params: payload,
        service: getAchievementsService,
        success: actionType.GET_ACHIEVEMENTS_SUCCESS,
        failed: actionType.GET_ACHIEVEMENTS_FAILED
    });
}

function* postAchievements({ payload }) {
    yield processAction({
        params: payload,
        service: postAchievementsService,
        success: actionType.POST_ACHIEVEMENTS_SUCCESS,
        failed: actionType.POST_ACHIEVEMENTS_FAILED
    });
}

function* putAchievements({ payload }) {
    yield processAction({
        params: payload,
        service: putAchievementsService,
        success: actionType.PUT_ACHIEVEMENTS_SUCCESS,
        failed: actionType.PUT_ACHIEVEMENTS_FAILED
    });
}

function* deleteAchievements({ payload }) {
    yield processAction({
        params: payload,
        service: deleteAchievementsService,
        success: actionType.DELETE_ACHIEVEMENTS_SUCCESS,
        failed: actionType.DELETE_ACHIEVEMENTS_FAILED
    });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetAchievements() {
    yield takeLatest(actionType.GET_ACHIEVEMENTS, getAchievements)
}

function* watchPostAchievements() {
    yield takeLatest(actionType.POST_ACHIEVEMENTS, postAchievements)
}

function* watchPutAchievements() {
    yield takeLatest(actionType.PUT_ACHIEVEMENTS, putAchievements)
}

function* watchDeleteAchievements() {
    yield takeLatest(actionType.DELETE_ACHIEVEMENTS, deleteAchievements)
}

export default [
    watchGetAchievements(),
    watchPostAchievements(),
    watchPutAchievements(),
    watchDeleteAchievements(),
];