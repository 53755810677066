import { all, takeEvery } from "redux-saga/effects";
import processAction from "../../utils/processAction";
import { actionTypes } from "./reducer";
import {
  login as loginService,
  logout as logoutService
} from "./service";


// ************************************ //
// ************* WORKERS ************** //
// ************************************ //

function* login({ payload }) {
  yield processAction({
    service: loginService,
    params: payload,
    success: actionTypes.LOGIN_SUCCESS,
    failed: actionTypes.LOGIN_FAILED
  });
}

function* logout({ payload }) {
  yield processAction({
    service: logoutService,
    params: payload,
    success: actionTypes.LOGOUT_SUCCESS,
    failed: actionTypes.LOGOUT_FAILED
  });
}

// ************************************* //
// ************* WATCHERS ************** //
// ************************************* //

function* watchLogin() {
  yield all([takeEvery(actionTypes.LOGIN, login)]);
}

function* watchLogout() {
  yield all([takeEvery(actionTypes.LOGOUT, logout)]);
}

export default [
  watchLogin(),
  watchLogout(),
];
