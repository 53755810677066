import { takeLatest } from "redux-saga/effects";
import processAction from "../../utils/processAction";
import { actionType } from "./reducer";
import {
  getTransactions as getTransactionsService,
  postTransaction as postTransactionService,
  putTransaction as putTransactionService,
  getExportTransactions as getExportTransactionsService,
  deleteTransactions,
} from "./service";

/************************/
/******* WORKERS ********/
/************************/

function* getTransactions({ payload }) {
  yield processAction({
    params: payload,
    service: getTransactionsService,
    success: actionType.GET_TRANSACTIONS_SUCCESS,
    failed: actionType.GET_TRANSACTIONS_FAILED,
  });
}

function* postTransaction({ payload }) {
  yield processAction({
    params: payload,
    service: postTransactionService,
    success: actionType.POST_TRANSACTION_SUCCESS,
    failed: actionType.POST_TRANSACTION_FAILED,
  });
}

function* putTransaction({ payload }) {
  yield processAction({
    params: payload,
    service: putTransactionService,
    success: actionType.PUT_TRANSACTION_SUCCESS,
    failed: actionType.PUT_TRANSACTION_FAILED,
  });
}

function* getExportTransactions({ payload }) {
  yield processAction({
    params: payload,
    service: getExportTransactionsService,
    success: actionType.GET_EXPORT_TRANSACTIONS_SUCCESS,
    failed: actionType.GET_EXPORT_TRANSACTIONS_FAILED,
  });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetTransactions() {
  yield takeLatest(actionType.GET_TRANSACTIONS, getTransactions);
}

function* watchPostTransaction() {
  yield takeLatest(actionType.POST_TRANSACTION, postTransaction);
}

function* watchPutTransaction() {
  yield takeLatest(actionType.PUT_TRANSACTION, putTransaction);
}

function* watchGetExportTransactions() {
  yield takeLatest(actionType.GET_EXPORT_TRANSACTIONS, getExportTransactions);
}

function* watchDeleteTransaction() {
  yield takeLatest(actionType.DELETE_TRANSACTION, function* ({ payload }) {
    yield processAction({
      params: {id: payload},
      service: deleteTransactions,
      success: actionType.DELETE_TRANSACTION_SUCCESS,
      failed: actionType.DELETE_TRANSACTION_FAILED,
    });
  });
}

export default [
  watchGetTransactions(),
  watchPostTransaction(),
  watchPutTransaction(),
  watchGetExportTransactions(),
  watchDeleteTransaction(),
];
