import { all } from "redux-saga/effects";
import authSagas from "../modules/auth/saga";
import dashboardSagas from "../modules/dashboard/saga";
import customerListSagas from "../modules/customer/pages/customer-list/saga";
import customerTransactionSagas from "../modules/customer/pages/transactions/saga";
import customerFeedbackSagas from "../modules/customer/pages/feedback/saga";
import customerAnalyticsSagas from "../modules/customer/pages/analytics/saga";
import userSagas from "../modules/settings/pages/users/saga";
import branchListSagas from "../modules/branch/pages/list/saga";
import manageBranchSagas from "../modules/branch/pages/manage-branches/pages/saga";
import branchAnalyticSagas from "../modules/branch/pages/profile/pages/analytics/saga";
import branchTransactionsSagas from "../modules/branch/pages/profile/pages/transactions/saga";
import eventSagas from "../modules/engagements/pages/events/saga";
import segmentSagas from "../modules/engagements/pages/segment/saga";
import notificationSagas from "../modules/engagements/pages/notification/saga";
import feedbackSagas from "../modules/engagements/pages/feedback/saga";
import transactionSagas from '../modules/transactions/saga';
import transactionMonitorSagas from '../modules/transaction-monitor/saga';
import mechanicSagas from "../modules/settings/pages/mechanics/pages/programMechanics/saga";
import pointConversionSagas from "../modules/settings/pages/mechanics/pages/pointConversion/saga";
import achievementsSagas from "../modules/settings/pages/mechanics/pages/achievements/saga";


export default function* rootSaga() {
	yield all([
		...authSagas,

		// Dashboard
		...dashboardSagas,

		// Branch
		...branchListSagas,
		...manageBranchSagas,
		...branchAnalyticSagas,
		...branchTransactionsSagas,

		// Customers
		...customerListSagas,
		...customerAnalyticsSagas,
		...customerFeedbackSagas,
		...customerTransactionSagas,

		// Transactions
		...transactionSagas,
		...transactionMonitorSagas,

		// Customer Engagements
		...eventSagas,
		...segmentSagas,
		...notificationSagas,
		...feedbackSagas,

		// Settings
		...userSagas,
		...mechanicSagas,
		...pointConversionSagas,
		...achievementsSagas
	]);
}
