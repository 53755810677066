export const actionType = {
    GET_CUSTOMER_TRANSACTION: "GET_CUSTOMER_TRANSACTION",
    GET_CUSTOMER_TRANSACTION_SUCCESS: "GET_CUSTOMER_TRANSACTION_SUCCESS",
    GET_CUSTOMER_TRANSACTION_FAILED: "GET_CUSTOMER_TRANSACTION_FAILED",

    UPDATE_CUSTOMER_TRANSACTION: "UPDATE_CUSTOMER_TRANSACTION",
    UPDATE_CUSTOMER_TRANSACTION_SUCCESS: "UPDATE_CUSTOMER_TRANSACTION_SUCCESS",
    UPDATE_CUSTOMER_TRANSACTION_FAILED: "UPDATE_CUSTOMER_TRANSACTION_FAILED",

    // NON-API REQUEST
    REMOVE_CUSTOMER_TRANSACTIONS_TOAST: "REMOVE_CUSTOMER_TRANSACTIONS_TOAST",
}

export const actionCreators = {
    removeCustomerTransactionsToast: () => ({
        type: actionType.REMOVE_CUSTOMER_TRANSACTIONS_TOAST
    }),
    getCustomerTransaction: payload => ({
        type: actionType.GET_CUSTOMER_TRANSACTION, payload
    }),
    updateCustomerTransaction: payload => ({
        type: actionType.UPDATE_CUSTOMER_TRANSACTION, payload
    })
}

const initialState = {
    transactions: null,
    meta: null,
    isLoading: false,
    error: null,
    customerTransactionsToast: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.REMOVE_CUSTOMER_TRANSACTIONS_TOAST:
            return Object.assign({}, state, { customerTransactionsToast: null });

        case actionType.GET_CUSTOMER_TRANSACTION:
        case actionType.UPDATE_CUSTOMER_TRANSACTION:
            return Object.assign({}, state, { isLoading: true, error: null });

        case actionType.GET_CUSTOMER_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                transactions: action.payload,
            });

        case actionType.UPDATE_CUSTOMER_TRANSACTION_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                customerTransactionsToast: {
                    type: 'success',
                    message: 'Transaction Updated'
                }
            });

        case actionType.GET_CUSTOMER_TRANSACTION_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                customerTransactionsToast: {
                    message: action && action.error,
                    type: "failed",
                }
            });

        case actionType.UPDATE_CUSTOMER_TRANSACTION_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                customerTransactionsToast: {
                    message: action && action.error,
                    type: "failed",
                    request: "put"
                }
            });

        default:
            return state;
    }
}
