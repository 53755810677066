export const actionType = {

    GET_USERS: "GET_USERS",
    GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
    GET_USERS_FAILED: "GET_USERS_FAILED",

    POST_USER: "POST_USER",
    POST_USER_SUCCESS: "POST_USER_SUCCESS",
    POST_USER_FAILED: "POST_USER_FAILED",

    DELETE_USER: "DELETE_USER",
    DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
    DELETE_USER_FAILED: "DELETE_USER_FAILED",

    PUT_USER: "PUT_USER",
    PUT_USER_SUCCESS: "PUT_USER_SUCCESS",
    PUT_USER_FAILED: "PUT_USER_FAILED",

    // NON-API REQUEST
    REMOVE_USER_TOAST: "REMOVE_USER_TOAST",

}

export const actionCreators = {
    removeUserToast: () => ({
        type: actionType.REMOVE_USER_TOAST
    }),
    getUsers: payload => ({
        type: actionType.GET_USERS, payload
    }),
    postUser: payload => ({
        type: actionType.POST_USER, payload
    }),
    deleteUser: payload => ({
        type: actionType.DELETE_USER, payload
    }),
    putUser: payload => ({
        type: actionType.PUT_USER, payload
    }),
}

const initialState = {
    meta: null,
    isLoading: false,
    error: null,
    userToast: {},
    users: null,
}


export default (state = initialState, action) => {
    switch (action.type) {

        case actionType.REMOVE_USER_TOAST:
            return Object.assign({}, state, { userToast: null });

        case actionType.DELETE_USER:
        case actionType.POST_USER:
        case actionType.GET_USERS:
            return Object.assign({}, state, { isLoading: true, error: null });

        case actionType.GET_USERS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                users: action.payload,
            });

        case actionType.POST_USER_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                userToast: {
                    message: "Successfully added new user",
                    type: "success",
                    request: "post"
                }
            });

        case actionType.DELETE_USER_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                userToast: {
                    message: action.payload.message,
                    type: "success",
                    request: "delete"
                }
            });

        case actionType.PUT_USER_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                userToast: {
                    message: "Successfully updated user",
                    type: "success",
                    request: "put"
                }
            });

        case actionType.DELETE_USER_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                userToast: {
                    message: action.message ? action.message : 'Failed',
                    type: "failed",
                    request: "delete"
                }
            });

        case actionType.POST_USER_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                userToast: {
                    // message: action.error,
                    type: "failed",
                    request: "post"
                }
            });

        case actionType.GET_USERS_FAILED:
        case actionType.PUT_USER_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                userToast: {
                    // message: action.error,
                    type: "failed",
                    request: "get"
                }
            });

        default:
            return state;
    }
}
