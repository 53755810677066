export const actionType = {
  GET_FEEDBACKS: "GET_FEEDBACKS",
  GET_FEEDBACKS_SUCCESS: "GET_FEEDBACKS_SUCCESS",
  GET_FEEDBACKS_FAILED: "GET_FEEDBACKS_FAILED",

  GET_ALL_FEEDBACKS: "GET_ALL_FEEDBACKS",
  GET_ALL_FEEDBACKS_SUCCESS: "GET_ALL_FEEDBACKS_SUCCESS",
  GET_ALL_FEEDBACKS_FAILED: "GET_ALL_FEEDBACKS_FAILED",

  POST_FEEDBACK: "POST_FEEDBACK",
  POST_FEEDBACK_SUCCESS: "POST_FEEDBACK_SUCCESS",
  POST_FEEDBACK_FAILED: "POST_FEEDBACK_FAILED",

  PUT_FEEDBACK: "PUT_FEEDBACK",
  PUT_FEEDBACK_SUCCESS: "PUT_FEEDBACK_SUCCESS",
  PUT_FEEDBACK_FAILED: "PUT_FEEDBACK_FAILED",

  DELETE_FEEDBACK: "DELETE_FEEDBACK",
  DELETE_FEEDBACK_SUCCESS: "DELETE_FEEDBACK_SUCCESS",
  DELETE_FEEDBACK_FAILED: "DELETE_FEEDBACK_FAILED",

  // NON-API REQUEST
  REMOVE_FEEDBACK_TOAST: "REMOVE_FEEDBACK_TOAST",
};

export const actionCreators = {
  removeFeedbackToast: () => ({
    type: actionType.REMOVE_FEEDBACK_TOAST,
  }),
  getFeedbacks: (payload) => ({
    type: actionType.GET_FEEDBACKS,
    payload,
  }),
  getAllFeedbacks: () => ({
    type: actionType.GET_ALL_FEEDBACKS,
  }),
  postFeedback: (payload) => ({
    type: actionType.POST_FEEDBACK,
    payload,
  }),
  putFeedback: (payload) => ({
    type: actionType.PUT_FEEDBACK,
    payload,
  }),
  deleteFeedback: (payload) => ({
    type: actionType.DELETE_FEEDBACK,
    payload,
  }),
};

const initialState = {
  meta: null,
  isLoading: false,
  error: null,
  feedbackToast: null,
  feedbacks: null,
  all: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.REMOVE_FEEDBACK_TOAST:
      return Object.assign({}, state, { feedbackToast: null });

    case actionType.DELETE_FEEDBACKS:
    case actionType.PUT_FEEDBACKS:
    case actionType.POST_FEEDBACKS:
    case actionType.GET_FEEDBACKS:
    case actionType.GET_ALL_FEEDBACKS:
      return Object.assign({}, state, { isLoading: true, error: null });

    case actionType.GET_FEEDBACKS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        feedbacks: action.payload,
      });

    case actionType.GET_ALL_FEEDBACKS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        all: action.payload,
      });

    case actionType.POST_FEEDBACK_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        feedbackToast: {
          message: "Successfully added a new feedback!",
          type: "success",
          request: "post",
        },
      });

    case actionType.PUT_FEEDBACK_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        feedbackToast: {
          type: "success",
          request: "put",
        },
      });

    case actionType.DELETE_FEEDBACK_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        feedbackToast: {
          message: "Successfully deleted feedback!",
          type: "success",
          request: "delete",
        },
      });

    case actionType.GET_ALL_FEEDBACKS_FAILED:
    case actionType.DELETE_FEEDBACK_FAILED:
    case actionType.PUT_FEEDBACK_FAILED:
    case actionType.POST_FEEDBACK_FAILED:
    case actionType.GET_FEEDBACKS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        feedbackToast: {
          message: action.error,
          type: "failed",
        },
      });

    default:
      return state;
  }
};
