import { takeLatest } from 'redux-saga/effects';
import processAction from "../../../../../utils/processAction";
import { actionType } from './reducer';
import {
    getSingleBranch as getSingleBranchService,
    postAddBranch as postAddBranchService,
    putBranch as putBranchService
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getSingleBranch({ id }) {
    yield processAction({
        params: id,
        service: getSingleBranchService,
        success: actionType.GET_SINGLE_BRANCH_SUCCESS,
        failed: actionType.GET_SINGLE_BRANCH_FAILED
    });
}

function* postAddBranch({ payload }) {
    yield processAction({
        params: payload,
        service: postAddBranchService,
        success: actionType.POST_ADD_BRANCH_SUCCESS,
        failed: actionType.POST_ADD_BRANCH_FAILED
    });
}

function* putBranch({ payload }) {
    yield processAction({
        params: payload,
        service: putBranchService,
        success: actionType.PUT_BRANCH_SUCCESS,
        failed: actionType.PUT_BRANCH_FAILED
    });
}

/**************************/
/******** WATCHERS ********/
/**************************/
function* watchGetSingleBranch() {
    yield takeLatest(actionType.GET_SINGLE_BRANCH, getSingleBranch)
}
function* watchPostAddBranch() {
    yield takeLatest(actionType.POST_ADD_BRANCH, postAddBranch)
}
function* watchPutBranch() {
    yield takeLatest(actionType.PUT_BRANCH, putBranch)
}

export default [
    watchGetSingleBranch(),
    watchPostAddBranch(),
    watchPutBranch()
];