import { call, put } from "redux-saga/effects";

export default function* processAction(action) {
  const { params, service, success, failed } = action;

  let payload = "";
  try {
    if (Array.isArray(params)) {
      let parameters = Object.values(params).map((item) => item);
      payload = yield call(service, ...parameters);
    } else {
      payload = yield call(service, params);
    }
    yield put({
      type: success,
      payload,
    });
  } catch (error) {
    yield put({ type: failed, error });
  }
}
