export const actionType = {

    GET_MECHANICS: "GET_MECHANICS",
    GET_MECHANICS_SUCCESS: "GET_MECHANICS_SUCCESS",
    GET_MECHANICS_FAILED: "GET_MECHANICS_FAILED",

    POST_MECHANICS: "POST_MECHANICS",
    POST_MECHANICS_SUCCESS: "POST_MECHANICS_SUCCESS",
    POST_MECHANICS_FAILED: "POST_MECHANICS_FAILED",

    PUT_MECHANICS: "PUT_MECHANICS",
    PUT_MECHANICS_SUCCESS: "PUT_MECHANICS_SUCCESS",
    PUT_MECHANICS_FAILED: "PUT_MECHANICS_FAILED",

    PUT_MECHANICS_STATUS: "PUT_MECHANICS_STATUS",
    PUT_MECHANICS_STATUS_SUCCESS: "PUT_MECHANICS_STATUS_SUCCESS",
    PUT_MECHANICS_STATUS_FAILED: "PUT_MECHANICS_STATUS_FAILED",

    DELETE_MECHANICS: "DELETE_MECHANICS",
    DELETE_MECHANICS_SUCCESS: "DELETE_MECHANICS_SUCCESS",
    DELETE_MECHANICS_FAILED: "DELETE_MECHANICS_FAILED",

    // NON-API REQUEST
    REMOVE_MECHANICS_TOAST: "REMOVE_MECHANICS_TOAST",
}

export const actionCreators = {
    removeMechanicsToast: () => ({
        type: actionType.REMOVE_MECHANICS_TOAST
    }),
    getMechanics: payload => ({
        type: actionType.GET_MECHANICS, payload
    }),
    postMechanics: payload => ({
        type: actionType.POST_MECHANICS, payload
    }),
    putMechanics: payload => ({
        type: actionType.PUT_MECHANICS, payload
    }),
    putMechanicsStatus: payload => ({
        type: actionType.PUT_MECHANICS_STATUS, payload
    }),
    deleteMechanics: payload => ({
        type: actionType.DELETE_MECHANICS, payload
    }),
}

const initialState = {
    meta: null,
    isLoading: false,
    error: null,
    mechanicsToast: null,
    mechanics: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.REMOVE_MECHANICS_TOAST:
            return Object.assign({}, state, { mechanicsToast: null });

        case actionType.DELETE_MECHANICS:
        case actionType.PUT_MECHANICS_STATUS:
        case actionType.PUT_MECHANICS:
        case actionType.POST_MECHANICS:
        case actionType.GET_MECHANICS:
            return Object.assign({}, state, { isLoading: true, error: null });

        case actionType.POST_MECHANICS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                mechanicsToast: {
                    message: 'Successfully added new program mechanic',
                    type: "success",
                    request: "post"
                }
            });

        case actionType.GET_MECHANICS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                mechanics: action.payload
            });

        case actionType.PUT_MECHANICS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                mechanicsToast: {
                    message: 'Successfully updated program mechanic',
                    type: "success",
                    request: "put"
                }
            });

        case actionType.PUT_MECHANICS_STATUS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                mechanicsToast: {
                    message: 'Successfully updated program mechanic',
                    type: "success",
                    request: "put"
                }
            });

        case actionType.DELETE_MECHANICS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                mechanicsToast: {
                    message: 'Successfully deleted program mechanic',
                    type: "success",
                    request: "delete"
                }
            });

        case actionType.DELETE_MECHANICS_FAILED:
        case actionType.PUT_MECHANICS_STATUS_FAILED:
        case actionType.PUT_MECHANICS_FAILED:
        case actionType.POST_MECHANICS_FAILED:
        case actionType.GET_MECHANICS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                mechanicsToast: {
                    message: action.error ? action.error : 'Failed',
                    type: "failed",
                    request: "post"
                }
            });

        default:
            return state;
    }
}
