export const actionType = {
  GET_TRANSACTIONS: "GET_TRANSACTIONS",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAILED: "GET_TRANSACTIONS_FAILED",

  DELETE_TRANSACTION: "DELETE_TRANSACTIONS",
  DELETE_TRANSACTION_SUCCESS: "DELETE_TRANSACTION_SUCCESS",
  DELETE_TRANSACTION_FAILED: "DELETE_TRANSACTION_FAILED",

  POST_TRANSACTION: "POST_TRANSACTION",
  POST_TRANSACTION_SUCCESS: "POST_TRANSACTION_SUCCESS",
  POST_TRANSACTION_FAILED: "POST_TRANSACTION_FAILED",

  PUT_TRANSACTION: "PUT_TRANSACTION",
  PUT_TRANSACTION_SUCCESS: "PUT_TRANSACTION_SUCCESS",
  PUT_TRANSACTION_FAILED: "PUT_TRANSACTION_FAILED",

  GET_EXPORT_TRANSACTIONS: "GET_EXPORT_TRANSACTIONS",
  GET_EXPORT_TRANSACTIONS_SUCCESS: "GET_EXPORT_TRANSACTIONS_SUCCESS",
  GET_EXPORT_TRANSACTIONS_FAILED: "GET_EXPORT_TRANSACTIONS_FAILED",

  // NON-API REQUEST
  REMOVE_TRANSACTIONS_TOAST: "REMOVE_TRANSACTIONS_TOAST",
};

export const actionCreators = {
  removeTransactionsToast: () => ({
    type: actionType.REMOVE_TRANSACTIONS_TOAST,
  }),
  getTransactions: (payload) => ({
    type: actionType.GET_TRANSACTIONS,
    payload,
  }),
  postTransaction: (payload) => ({
    type: actionType.POST_TRANSACTION,
    payload,
  }),
  putTransaction: (payload) => ({
    type: actionType.PUT_TRANSACTION,
    payload,
  }),
  getExportTransactions: (payload) => ({
    type: actionType.GET_EXPORT_TRANSACTIONS,
    payload,
  }),
  deleteTransactions: (payload) => ({
    type: actionType.DELETE_TRANSACTION,
    payload,
  }),
};

const initialState = {
  meta: null,
  isLoading: false,
  error: null,
  transactionsToast: null,
  transactions: [],
  csv: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.REMOVE_TRANSACTIONS_TOAST:
      return Object.assign({}, state, { transactionsToast: null });
    case actionType.DELETE_TRANSACTION:
    case actionType.GET_EXPORT_TRANSACTIONS:
    case actionType.GET_TRANSACTIONS:
    case actionType.POST_TRANSACTION:
    case actionType.PUT_TRANSACTION:
      return Object.assign({}, state, { isLoading: true, error: null });

    case actionType.GET_TRANSACTIONS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        transactions: action.payload,
      });

    case actionType.POST_TRANSACTION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        transactionsToast: {
          message: "Successfully added new transaction",
          type: "success",
          request: "post",
        },
      });
    case actionType.DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        transactionsToast: {
          message: action.payload.message,
          type: "success",
          request: "delete",
        },
      };
    case actionType.DELETE_TRANSACTION_FAILED:
      return {
        ...state,
        isLoading: false,
        error: null,
        transactionsToast: {
          message: "There's something wrong in server.",
          type: "failed",
          request: "delete",
        },
      };

    case actionType.PUT_TRANSACTION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        transactionsToast: {
          message: "Successfully updated transaction",
          type: "success",
          request: "put",
        },
      });

    case actionType.GET_EXPORT_TRANSACTIONS_SUCCESS:
      // action.payload.text().then((e) => {
      //     let url = encodeURI('data:text/csv;charset=utf-8,' + e)
      //     let link = document.createElement('a');
      //     link.href = url;
      //     link.setAttribute('download', `report.csv`);
      //     document.body.appendChild(link);
      //     link.click();
      //     link.parentNode.removeChild(link);
      // })
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        csv: action.payload,
        transactionsToast: {
          message: "Export Transaction History Success",
          type: "success-csv",
        },
      });

    case actionType.POST_TRANSACTION_FAILED:
    case actionType.PUT_TRANSACTION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        transactionsToast: {
          message: !action.error ? "Update Failed" : action.error,
          type: "failed",
          request: "put",
        },
      });

    case actionType.GET_EXPORT_TRANSACTIONS_FAILED:
    case actionType.GET_TRANSACTIONS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });

    default:
      return state;
    // break;
  }
};
