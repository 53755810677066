export const actionType = {

    GET_CUSTOMER_ANALYTICS: "GET_CUSTOMER_ANALYTICS",
    GET_CUSTOMER_ANALYTICS_SUCCESS: "GET_CUSTOMER_ANALYTICS_SUCCESS",
    GET_CUSTOMER_ANALYTICS_FAILED: "GET_CUSTOMER_ANALYTICS_FAILED",

    GET_CUSTOMER_TRANSACTIONS_GRAPH: "GET_CUSTOMER_TRANSACTIONS_GRAPH",
    GET_CUSTOMER_TRANSACTIONS_GRAPH_SUCCESS: "GET_CUSTOMER_TRANSACTIONS_GRAPH_SUCCESS",
    GET_CUSTOMER_TRANSACTIONS_GRAPH_FAILED: "GET_CUSTOMER_TRANSACTIONS_GRAPH_FAILED",

    // NON-API REQUEST
    REMOVE_CUSTOMER_ANALYTICS_TOAST: "REMOVE_CUSTOMER_ANALYTICS_TOAST",
}

export const actionCreators = {
    removeCustomerAnalyticsToast: () => ({
        type: actionType.REMOVE_CUSTOMER_TOAST
    }),
    getCustomerAnalytics: payload => ({
        type: actionType.GET_CUSTOMER_ANALYTICS, payload
    }),
    getCustomerTransactionsGraph: payload => ({
        type: actionType.GET_CUSTOMER_TRANSACTIONS_GRAPH, payload
    })
}

const initialState = {
    data: null,
    meta: null,
    isLoading: false,
    error: null,
    customerAnalyticsToast: null,
    customerAnalytics: null,
    customerTransactionsGraph: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.REMOVE_CUSTOMER_ANALYTICS_TOAST:
            return Object.assign({}, state, { customerAnalyticsToast: null });

        case actionType.GET_CUSTOMER_TRANSACTIONS_GRAPH:
        case actionType.GET_CUSTOMER_ANALYTICS:
            return Object.assign({}, state, { isLoading: true, error: null });

        case actionType.GET_CUSTOMER_ANALYTICS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                customerAnalytics: action.payload,
            });

        case actionType.GET_CUSTOMER_TRANSACTIONS_GRAPH_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                customerTransactionsGraph: action.payload,
                customerAnalyticsToast: {
                    message: '',
                    type: "success",
                }
            });

        case actionType.GET_CUSTOMER_TRANSACTIONS_GRAPH_FAILED:
        case actionType.GET_CUSTOMER_ANALYTICS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                customerAnalyticsToast: {
                    message: action && action.message,
                    type: "failed",
                }
            });

        default:
            return state;
        // break;
    }
}
