export const actionType = {
    GET_BRANCH_LIST: "GET_BRANCH_LIST",
    GET_BRANCH_LIST_SUCCESS: "GET_BRANCH_LIST_SUCCESS",
    GET_BRANCH_LIST_FAILED: "GET_BRANCH_LIST_FAILED",

    DELETE_BRANCH: "DELETE_BRANCH",
    DELETE_BRANCH_SUCCESS: "DELETE_BRANCH_SUCCESS",
    DELETE_BRANCH_FAILED: "DELETE_BRANCH_FAILED",

    // NON-API REQUEST
    REMOVE_BRANCH_TOAST: "REMOVE_BRANCH_TOAST",
}

export const actionCreators = {
    removeBranchToast: () => ({
        type: actionType.REMOVE_BRANCH_TOAST
    }),
    getBranchList: payload => ({
        type: actionType.GET_BRANCH_LIST, payload
    }),
    deleteBranch: payload => ({
        type: actionType.DELETE_BRANCH, payload
    }),
}

const initialState = {
    meta: null,
    isLoading: false,
    error: null,
    branchToast: null,
    branches: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.REMOVE_BRANCH_TOAST:
            return Object.assign({}, state, { branchToast: null });

        case actionType.DELETE_BRANCH:
        case actionType.GET_BRANCH_LIST:
            return Object.assign({}, state, { isLoading: true, error: null });

        case actionType.GET_BRANCH_LIST_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                branches: action.payload,
            });

        case actionType.DELETE_BRANCH_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                branchToast: {
                    message: 'Successfully Deleted Branch!',
                    type: "success",
                    request: "delete"
                }
            });

        case actionType.DELETE_BRANCH_FAILED:
        case actionType.GET_BRANCH_LIST_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error
            });

        default:
            return state;
    }
}
