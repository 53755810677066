export const actionType = {

    GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
    GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
    GET_NOTIFICATIONS_FAILED: "GET_NOTIFICATIONS_FAILED",

    POST_NOTIFICATIONS: "POST_NOTIFICATIONS",
    POST_NOTIFICATIONS_SUCCESS: "POST_NOTIFICATIONS_SUCCESS",
    POST_NOTIFICATIONS_FAILED: "POST_NOTIFICATIONS_FAILED",

    PUT_NOTIFICATIONS: "PUT_NOTIFICATIONS",
    PUT_NOTIFICATIONS_SUCCESS: "PUT_NOTIFICATIONS_SUCCESS",
    PUT_NOTIFICATIONS_FAILED: "PUT_NOTIFICATIONS_FAILED",

    DELETE_NOTIFICATIONS: "DELETE_NOTIFICATIONS",
    DELETE_NOTIFICATIONS_SUCCESS: "DELETE_NOTIFICATIONS_SUCCESS",
    DELETE_NOTIFICATIONS_FAILED: "DELETE_NOTIFICATIONS_FAILED",

    // NON-API REQUEST
    REMOVE_NOTIFICATIONS_TOAST: "REMOVE_NOTIFICATIONS_TOAST",

}

export const actionCreators = {
    removeNotificationsToast: () => ({
        type: actionType.REMOVE_NOTIFICATIONS_TOAST
    }),
    getNotifications: payload => ({
        type: actionType.GET_NOTIFICATIONS, payload
    }),
    postNotifications: payload => ({
        type: actionType.POST_NOTIFICATIONS, payload
    }),
    putNotifications: payload => ({
        type: actionType.PUT_NOTIFICATIONS, payload
    }),
    deleteNotifications: payload => ({
        type: actionType.DELETE_NOTIFICATIONS, payload
    }),
}

const initialState = {
    meta: null,
    isLoading: false,
    error: null,
    notificationsToast: null,
    notifications: null,
}


export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.REMOVE_NOTIFICATIONS_TOAST:
            return Object.assign({}, state, { notificationsToast: null });

        case actionType.GET_NOTIFICATIONS:
        case actionType.POST_NOTIFICATIONS:
        case actionType.PUT_NOTIFICATIONS:
        case actionType.DELETE_NOTIFICATIONS:
            return Object.assign({}, state, { isLoading: true, error: null });

        case actionType.GET_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                notifications: action.payload,
            });

        case actionType.POST_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                notificationsToast: {
                    message: 'Successfully Added New Notification!',
                    type: "success",
                    request: "post"
                }
            });

        case actionType.PUT_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                notificationsToast: {
                    message: 'Successfully Updated Notification!',
                    type: "success",
                    request: "put"
                }
            });

        case actionType.DELETE_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                notificationsToast: {
                    message: 'Successfully Deleted Notification!',
                    type: "success",
                    request: "delete"
                }
            });

        case actionType.GET_NOTIFICATIONS_FAILED:
        case actionType.POST_NOTIFICATIONS_FAILED:
        case actionType.PUT_NOTIFICATIONS_FAILED:
        case actionType.DELETE_NOTIFICATIONS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                notificationsToast: {
                    message: action.error,
                    type: "failed",
                    request: "post"
                }
            });

        default:
            return state;
    }
}
