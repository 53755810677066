import request from "../../../../utils/api";

const url = "notifications";

export const getNotifications = params => request(url, { params });
export const postNotifications = body => request(url, { method: 'post', body });

export const putNotifications = (body) => {
    return
    // request(`${version}/${body.id}`, { method: 'put', body: body.update });
}

export const deleteNotifications = body => request(`${url}/${body}`, { method: 'delete' });