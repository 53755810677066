
// Action Types
export const actionTypes = {
    GET_TOTALS: "GET_TOTALS",
    GET_TOTALS_SUCCESS: "GET_TOTALS_SUCCESS",
    GET_TOTALS_FAILED: "GET_TOTALS_FAILED",

    GET_TOP_BRANCHES: "GET_TOP_BRANCHES",
    GET_TOP_BRANCHES_SUCCESS: "GET_TOP_BRANCHES_SUCCESS",
    GET_TOP_BRANCHES_FAILED: "GET_TOP_BRANCHES_FAILED",

    GET_TOP_CUSTOMERS: "GET_TOP_CUSTOMERS",
    GET_TOP_CUSTOMERS_SUCCESS: "GET_TOP_CUSTOMERS_SUCCESS",
    GET_TOP_CUSTOMERS_FAILED: "GET_TOP_CUSTOMERS_FAILED",

    GET_PROFILES: "GET_PROFILES",
    GET_PROFILES_SUCCESS: "GET_PROFILES_SUCCESS",
    GET_PROFILES_FAILED: "GET_PROFILES_FAILED",

    GET_RANGED_TOTALS: "GET_RANGED_TOTALS",
    GET_RANGED_TOTALS_SUCCESS: "GET_RANGED_TOTALS_SUCCESS",
    GET_RANGED_TOTALS_FAILED: "GET_RANGED_TOTALS_FAILED",

    GET_CUSTOMERS_GRAPH: "GET_CUSTOMERS_GRAPH",
    GET_CUSTOMERS_GRAPH_SUCCESS: "GET_CUSTOMERS_GRAPH_SUCCESS",
    GET_CUSTOMERS_GRAPH_FAILED: "GET_CUSTOMERS_GRAPH_FAILED",

    GET_VISITS: "GET_VISITS",
    GET_VISITS_SUCCESS: "GET_VISITS_SUCCESS",
    GET_VISITS_FAILED: "GET_VISITS_FAILED",

    GET_TRANSACTIONS_GRAPH: "GET_TRANSACTIONS_GRAPH",
    GET_TRANSACTIONS_GRAPH_SUCCESS: "GET_TRANSACTIONS_GRAPH_SUCCESS",
    GET_TRANSACTIONS_GRAPH_FAILED: "GET_TRANSACTIONS_GRAPH_FAILED",
};

// Action creators
export const actionCreators = {
    getTotals: payload => ({
        type: actionTypes.GET_TOTALS, payload
    }),
    getTopBranches: payload => ({
        type: actionTypes.GET_TOP_BRANCHES, payload
    }),
    getTopCustomers: payload => ({
        type: actionTypes.GET_TOP_CUSTOMERS, payload
    }),
    getProfiles: payload => ({
        type: actionTypes.GET_PROFILES, payload
    }),
    getRangedTotals: payload => ({
        type: actionTypes.GET_RANGED_TOTALS, payload
    }),
    getCustomersGraph: payload => ({
        type: actionTypes.GET_CUSTOMERS_GRAPH, payload
    }),
    getVisits: payload => ({
        type: actionTypes.GET_VISITS, payload
    }),
    getTransactionsGraph: payload => ({
        type: actionTypes.GET_TRANSACTIONS_GRAPH, payload
    })
};

// Reducer
const initialState = {
    isLoadingTotals: false,
    isLoadingTopBranches: false,
    isLoadingTopCustomers: false,
    isLoadingProfiles: false,
    isLoadingRangedTotals: false,
    isLoadingCustomersGraph: false,
    isLoadingTransactionsGraph: false,
    isLoadingVisits: false,
    accessToken: null,
    error: null,
    totals: null,
    topBranches: null,
    topCustomers: null,
    profiles: null,
    rangedTotals: null,
    customersGraph: null,
    transactionsGraph: null,
    visits: null,
};

export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_VISITS:
            return Object.assign({}, state, { isLoadingVisits: true, error: null });
        case actionTypes.GET_TRANSACTIONS_GRAPH:
            return Object.assign({}, state, { isLoadingTransactionGraph: true, error: null });
        case actionTypes.GET_CUSTOMERS_GRAPH:
            return Object.assign({}, state, { isLoadingCustomersGraph: true, error: null });
        case actionTypes.GET_RANGED_TOTALS:
            return Object.assign({}, state, { isLoadingRangedTotals: true, error: null });
        case actionTypes.GET_PROFILES:
            return Object.assign({}, state, { isLoadingProfiles: true, error: null });
        case actionTypes.GET_TOP_BRANCHES:
            return Object.assign({}, state, { isLoadingTopBranches: true, error: null });
        case actionTypes.GET_TOTALS:
            return Object.assign({}, state, { isLoadingTotals: true, error: null });
        case actionTypes.GET_TOP_CUSTOMERS:
            return Object.assign({}, state, { isLoadingTopCustomers: true, error: null });

        case actionTypes.GET_TOTALS_SUCCESS:
            return Object.assign({}, state, {
                isLoadingTotals: false,
                error: null,
                totals: action.payload
            });

        case actionTypes.GET_TOP_BRANCHES_SUCCESS:
            return Object.assign({}, state, {
                isLoadingTopBranches: false,
                error: null,
                topBranches: action.payload
            });

        case actionTypes.GET_TOP_CUSTOMERS_SUCCESS:
            return Object.assign({}, state, {
                isLoadingTopCustomers: false,
                error: null,
                topCustomers: action.payload
            });

        case actionTypes.GET_PROFILES_SUCCESS:
            return Object.assign({}, state, {
                isLoadingProfiles: false,
                error: null,
                profiles: action.payload
            });

        case actionTypes.GET_RANGED_TOTALS_SUCCESS:
            return Object.assign({}, state, {
                isLoadingRangedTotals: false,
                error: null,
                rangedTotals: action.payload
            });

        case actionTypes.GET_CUSTOMERS_GRAPH_SUCCESS:
            return Object.assign({}, state, {
                isLoadingCustomersGraph: false,
                error: null,
                customersGraph: action.payload
            });

        case actionTypes.GET_VISITS_SUCCESS:
            return Object.assign({}, state, {
                isLoadingVisits: false,
                error: null,
                visits: action.payload
            });

        case actionTypes.GET_TRANSACTIONS_GRAPH_SUCCESS:
            return Object.assign({}, state, {
                isLoadingTransactionsGraph: false,
                error: null,
                transactionsGraph: action.payload
            });

        case actionTypes.GET_TRANSACTIONS_GRAPH_FAILED:
            return Object.assign({}, state, { isLoadingTransactionsGraph: false, error: action.error});
        case actionTypes.GET_CUSTOMERS_GRAPH_FAILED:
            return Object.assign({}, state, { isLoadingCustomersGraph: false, error: action.error});
        case actionTypes.GET_VISITS_FAILED:
            return Object.assign({}, state, { isLoadingVisits: false, error: action.error});
        case actionTypes.GET_PROFILES_FAILED:
            return Object.assign({}, state, { isLoadingProfiles: false, error: action.error});
        case actionTypes.GET_TOP_CUSTOMERS_FAILED:
            return Object.assign({}, state, { isLoadingTopCustomers: false, error: action.error});
        case actionTypes.GET_TOP_CUSTOMERS_FAILED:
            return Object.assign({}, state, { isLoadingTopCustomers: false, error: action.error});
        case actionTypes.GET_RANGED_TOTALS_FAILED:
            return Object.assign({}, state, { isLoadingRangedTotals: false, error: action.error});
        case actionTypes.GET_TOTALS_FAILED:
            return Object.assign({}, state, { isLoadingTotals: false, error: action.error});
        case actionTypes.GET_TOP_BRANCHES_FAILED:
            return Object.assign({}, state, { isLoadingTopBranches: false, error: action.error});

        default:
            return state;
    }
};
