import Loadable from "react-loadable";
import LoadingComponent from "../../commons/loader";

export default [
  {
    path: "/branches/add",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/manage-branches/pages/add/pages"),
      loading: LoadingComponent
    })
  },
  {
    path: "/branches/:id/edit",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/manage-branches/pages/edit/pages"),
      loading: LoadingComponent
    })
  },
  {
    path: "/branches/",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/list/pages"),
      loading: LoadingComponent
    })
  },
  {
    path: "/branches/:id/analytics",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/profile/pages/analytics/pages"),
      loading: LoadingComponent
    })
  },
  {
    path: "/branches/:id/transactions",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/profile/pages/transactions/pages"),
      loading: LoadingComponent
    })
  }
];
