import Loadable from "react-loadable";
import LoadingComponent from "../../../../../../commons/loader";
export default [
    {
        path: "/settings/mechanics/point-conversion",
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import("./pages/index"),
            loading: LoadingComponent
        })
    }
];
