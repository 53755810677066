import { setToken, deleteToken } from "../../utils/token";

// Action Types
export const actionTypes = {

	LOGIN: "LOGIN",
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_FAILED: "LOGIN_FAILED",

	LOGOUT: "LOGOUT",
	LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
	LOGOUT_FAILED: "LOGOUT_FAILED",

	REMOVE_AUTH_TOAST: "REMOVE_AUTH_TOAST",
};

// Action creators
export const actionCreators = {
	removeAuthToast: () => ({
		type: actionTypes.REMOVE_AUTH_TOAST
	}),
	login: payload => ({
		type: actionTypes.LOGIN, payload
	}),
	logout: payload => ({
		type: actionTypes.LOGOUT, payload
	}),
};

// Reducer
const initialState = {
	isAuthenticated: false,
	isLoading: false,
	accessToken: null,
	errors: null,
	permissions: [],
	logoutSuccess: false,
	authToast: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.REMOVE_AUTH_TOAST:
			return Object.assign({}, state, { authToast: null });

		case actionTypes.LOGIN:
			return Object.assign({}, state, {
				isLoading: true,
				errors: null
			});
		case actionTypes.LOGIN_SUCCESS:
			setToken(action.payload.access_token);
			return Object.assign({}, state, {
				accessToken: action.payload.access_token,
				user: action.payload.user,
				isAuthenticated: true,
				isLoading: false,
				errors: null,
				authToast: { message: "Login Success!", type: "success", request: "post" }
			});
		case actionTypes.LOGIN_FAILED:
			return Object.assign({}, state, {
				...state,
				isAuthenticated: false,
				isLoading: false,
				accessToken: null,
				errors: action.errors,
				authToast: { message: "Please try again. Email or password did not match!", type: "failed", request: "post" }
			});

		case actionTypes.LOGOUT:
			return {
				...state,
				logoutSuccess: false,
				isLoading: true,
				errors: null
			};
		case actionTypes.LOGOUT_SUCCESS:
			deleteToken();
			return {
				...state,
				isAuthenticated: false,
				isLoading: false,
				accessToken: null,
				user: null,
				errors: null,
				logoutSuccess: true,
				authToast: { message: "Logout Success!", type: "success", request: "post" }
			};
		case actionTypes.LOGOUT_FAILED:
			return {
				...state,
				isAuthenticated: false,
				isLoading: false,
				errors: action.errors
			};
		default:
			return state;
	}
};
