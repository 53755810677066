import auth from "../modules/auth/routes";
import dashboard from "../modules/dashboard/routes";
import engagements from "../modules/engagements/routes";
import branch from "../modules/branch/routes";
import transactions from "../modules/transactions/routes";
import transactionMonitor from "../modules/transaction-monitor/routes";
import customer from "../modules/customer/routes";
import settings from "../modules/settings/routes";

export default [
  ...auth,
  ...dashboard,
  ...transactions,
  ...branch,
  ...customer,
  ...settings,
  ...engagements,
  ...transactionMonitor
];
