// index.js
import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

// import "./index.css";

const BreadcrumbComponent = ({ breadcrumbList }) => (
  <Breadcrumb className="breadcrumb" separator=">" style={{ margin: "20px 0" }}>
    {
      breadcrumbList.map(item => (
        <Breadcrumb.Item key={item.title}>
          {item.url ? (
            <Link to={item.url}>{item.title}</Link>
          ) : (
              item.title
            )}
        </Breadcrumb.Item>
      ))
    }
  </Breadcrumb>
);

export default BreadcrumbComponent;