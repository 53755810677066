export const actionType = {

    GET_ACHIEVEMENTS: "GET_ACHIEVEMENTS",
    GET_ACHIEVEMENTS_SUCCESS: "GET_ACHIEVEMENTS_SUCCESS",
    GET_ACHIEVEMENTS_FAILED: "GET_ACHIEVEMENTS_FAILED",

    POST_ACHIEVEMENTS: "POST_ACHIEVEMENTS",
    POST_ACHIEVEMENTS_SUCCESS: "POST_ACHIEVEMENTS_SUCCESS",
    POST_ACHIEVEMENTS_FAILED: "POST_ACHIEVEMENTS_FAILED",

    PUT_ACHIEVEMENTS: "PUT_ACHIEVEMENTS",
    PUT_ACHIEVEMENTS_SUCCESS: "PUT_ACHIEVEMENTS_SUCCESS",
    PUT_ACHIEVEMENTS_FAILED: "PUT_ACHIEVEMENTS_FAILED",

    DELETE_ACHIEVEMENTS: "DELETE_ACHIEVEMENTS",
    DELETE_ACHIEVEMENTS_SUCCESS: "DELETE_ACHIEVEMENTS_SUCCESS",
    DELETE_ACHIEVEMENTS_FAILED: "DELETE_ACHIEVEMENTS_FAILED",

    // NON-API REQUEST
    REMOVE_ACHIEVEMENTS_TOAST: "REMOVE_ACHIEVEMENTS_TOAST",

}

export const actionCreators = {
    removeAchievementsToast: () => ({
        type: actionType.REMOVE_ACHIEVEMENTS_TOAST
    }),
    getAchievements: payload => ({
        type: actionType.GET_ACHIEVEMENTS, payload
    }),
    postAchievements: payload => ({
        type: actionType.POST_ACHIEVEMENTS, payload
    }),
    putAchievements: payload => ({
        type: actionType.PUT_ACHIEVEMENTS, payload
    }),
    deleteAchievements: payload => ({
        type: actionType.DELETE_ACHIEVEMENTS, payload
    }),
}

const initialState = {
    meta: null,
    isLoading: false,
    error: null,
    achievementsToast: null,
    achievements: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.REMOVE_ACHIEVEMENTS_TOAST:
            return Object.assign({}, state, { achievementsToast: null });

        case actionType.DELETE_ACHIEVEMENTS:
        case actionType.PUT_ACHIEVEMENTS:
        case actionType.POST_ACHIEVEMENTS:
        case actionType.GET_ACHIEVEMENTS:
            return Object.assign({}, state, { isLoading: true, error: null });

        case actionType.GET_ACHIEVEMENTS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                achievements: action.payload
            });

        case actionType.POST_ACHIEVEMENTS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                achievementsToast: {
                    message: "Successfully added new achievement",
                    type: "success",
                    request: "post"
                }
            });

        case actionType.PUT_ACHIEVEMENTS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                achievementsToast: {
                    message: "Successfully updated achievement",
                    type: "success",
                    request: "put"
                }
            });

        case actionType.DELETE_ACHIEVEMENTS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                achievementsToast: {
                    message: "Successfully deleted achievement",
                    type: "success",
                    request: "delete"
                }
            });

        case actionType.DELETE_ACHIEVEMENTS_FAILED:
        case actionType.GET_ACHIEVEMENTS_FAILED:
        case actionType.POST_ACHIEVEMENTS_FAILED:
        case actionType.PUT_ACHIEVEMENTS_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                achievementsToast: {
                    message: action.error ? action.error : 'Failed',
                    type: "failed",
                    // request: "post"
                }
            });

        default:
            return state;
    }
}
