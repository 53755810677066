import { takeLatest } from 'redux-saga/effects';
import processAction from "../../../../utils/processAction";
import { actionType } from './reducer';

import {
    getSegments as getSegmentsService,
    postSegment as postSegmentService,
    putSegment as putSegmentService,
    deleteSegment as deleteSegmentService,
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getSegments({ payload }) {
    yield processAction({
        params: payload,
        service: getSegmentsService,
        success: actionType.GET_SEGMENTS_SUCCESS,
        failed: actionType.GET_SEGMENTS_FAILED
    });
}

function* postSegment({ payload }) {
    yield processAction({
        params: payload,
        service: postSegmentService,
        success: actionType.POST_SEGMENT_SUCCESS,
        failed: actionType.POST_SEGMENT_FAILED
    });
}

function* putSegment({ payload }) {
    yield processAction({
        params: payload,
        service: putSegmentService,
        success: actionType.PUT_SEGMENT_SUCCESS,
        failed: actionType.PUT_SEGMENT_FAILED
    });
}

function* deleteSegment({ payload }) {
    yield processAction({
        params: payload,
        service: deleteSegmentService,
        success: actionType.DELETE_SEGMENT_SUCCESS,
        failed: actionType.DELETE_SEGMENT_FAILED
    });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetSegments() {
    yield takeLatest(actionType.GET_SEGMENTS, getSegments)
}

function* watchPostSegment() {
    yield takeLatest(actionType.POST_SEGMENT, postSegment)
}

function* watchPutSegment() {
    yield takeLatest(actionType.PUT_SEGMENT, putSegment)
}

function* watchDeleteSegment() {
    yield takeLatest(actionType.DELETE_SEGMENT, deleteSegment)
}

export default [
    watchGetSegments(),
    watchPostSegment(),
    watchPutSegment(),
    watchDeleteSegment(),
];