import request from "@utils/api";

const resource = "customers";

export const getCustomerList = (params) => request(resource, { params });

export const getCustomerDetail = ({ user_id }) =>
  request(`${resource}/${user_id}`);

export const putCustomerActivate = (params) =>
  request(`${resource}/${params.id}/activate`, { method: "put", params });

export const putCustomer = (body) =>
  request(`${resource}/${body.customer.id}`, { method: "put", body });

export const deleteCustomer = (body) =>
  request(resource, { body, method: "post" });

export const suspendCustomer = (params) =>
  request(`${resource}/${params.customerId}/suspend_account`, {
    method: "post",
  });
