import { takeLatest } from 'redux-saga/effects';
import processAction from "../../../../../../utils/processAction";
import { actionType } from './reducer';

import {
    getMechanics as getMechanicsService,
    postMechanics as postMechanicsService,
    putMechanics as putMechanicsService,
    putMechanicsStatus as putMechanicsStatusService,
    deleteMechanics as deleteMechanicsService,
} from './service';

/************************/
/******* WORKERS ********/
/************************/

function* getMechanics({ payload }) {
    yield processAction({
        params: payload,
        service: getMechanicsService,
        success: actionType.GET_MECHANICS_SUCCESS,
        failed: actionType.GET_MECHANICS_FAILED
    });
}

function* postMechanics({ payload }) {
    yield processAction({
        params: payload,
        service: postMechanicsService,
        success: actionType.POST_MECHANICS_SUCCESS,
        failed: actionType.POST_MECHANICS_FAILED
    });
}

function* putMechanics({ payload }) {
    yield processAction({
        params: payload,
        service: putMechanicsService,
        success: actionType.PUT_MECHANICS_SUCCESS,
        failed: actionType.PUT_MECHANICS_FAILED
    });
}

function* putMechanicsStatus({ payload }) {
    yield processAction({
        params: payload,
        service: putMechanicsStatusService,
        success: actionType.PUT_MECHANICS_STATUS_SUCCESS,
        failed: actionType.PUT_MECHANICS_STATUS_FAILED
    });
}

function* deleteMechanics({ payload }) {
    yield processAction({
        params: payload,
        service: deleteMechanicsService,
        success: actionType.DELETE_MECHANICS_SUCCESS,
        failed: actionType.DELETE_MECHANICS_FAILED
    });
}

/**************************/
/******** WATCHERS ********/
/**************************/

function* watchGetMechanics() {
    yield takeLatest(actionType.GET_MECHANICS, getMechanics)
}

function* watchPostMechanics() {
    yield takeLatest(actionType.POST_MECHANICS, postMechanics)
}

function* watchPutMechanics() {
    yield takeLatest(actionType.PUT_MECHANICS, putMechanics)
}

function* watchPutMechanicsStatus() {
    yield takeLatest(actionType.PUT_MECHANICS_STATUS, putMechanicsStatus)
}

function* watchDeleteMechanics() {
    yield takeLatest(actionType.DELETE_MECHANICS, deleteMechanics)
}

export default [
    watchGetMechanics(),
    watchPostMechanics(),
    watchPutMechanics(),
    watchPutMechanicsStatus(),
    watchDeleteMechanics(),
];