import request from "@utils/api";

const url = "transactions";

/**
 * Get Customer Transaction Monitor
 * 
 * @param {*} params 
 * @returns 
 */
export const getTransactionMonitor = (params) => {
  return request(`${url}/customer-transaction-monitor`, {params});
};
