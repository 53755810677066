export const actionType = {

    GET_POINT_CONVERSION: "GET_POINT_CONVERSION",
    GET_POINT_CONVERSION_SUCCESS: "GET_POINT_CONVERSION_SUCCESS",
    GET_POINT_CONVERSION_FAILED: "GET_POINT_CONVERSION_FAILED",

    POST_POINT_CONVERSION: "POST_POINT_CONVERSION",
    POST_POINT_CONVERSION_SUCCESS: "POST_POINT_CONVERSION_SUCCESS",
    POST_POINT_CONVERSION_FAILED: "POST_POINT_CONVERSION_FAILED",

    PUT_POINT_CONVERSION: "PUT_POINT_CONVERSION",
    PUT_POINT_CONVERSION_SUCCESS: "PUT_POINT_CONVERSION_SUCCESS",
    PUT_POINT_CONVERSION_FAILED: "PUT_POINT_CONVERSION_FAILED",

    DELETE_POINT_CONVERSION: "DELETE_POINT_CONVERSION",
    DELETE_POINT_CONVERSION_SUCCESS: "DELETE_POINT_CONVERSION_SUCCESS",
    DELETE_POINT_CONVERSION_FAILED: "DELETE_POINT_CONVERSION_FAILED",

    // NON-API REQUEST
    REMOVE_POINT_CONVERSION_TOAST: "REMOVE_POINT_CONVERSION_TOAST",

}

export const actionCreators = {
    removePointConversionToast: () => ({
        type: actionType.REMOVE_POINT_CONVERSION_TOAST
    }),
    getPointConversion: payload => ({
        type: actionType.GET_POINT_CONVERSION, payload
    }),
    postPointConversion: payload => ({
        type: actionType.POST_POINT_CONVERSION, payload
    }),
    putPointConversion: payload => ({
        type: actionType.PUT_POINT_CONVERSION, payload
    }),
    deletePointConversion: payload => ({
        type: actionType.DELETE_POINT_CONVERSION, payload
    }),
}

const initialState = {
    meta: null,
    isLoading: false,
    error: null,
    pointConversionToast: null,
    pointConversion: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.REMOVE_POINT_CONVERSION_TOAST:
            return Object.assign({}, state, { pointConversionToast: null });

        case actionType.DELETE_POINT_CONVERSION:
        case actionType.PUT_POINT_CONVERSION:
        case actionType.POST_POINT_CONVERSION:
        case actionType.GET_POINT_CONVERSION:
            return Object.assign({}, state, { isLoading: true, error: null });

        case actionType.GET_POINT_CONVERSION_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                pointConversion: action.payload
            });

        case actionType.POST_POINT_CONVERSION_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                pointConversionToast: {
                    message: 'Successfully added new point conversion!',
                    type: "success",
                    request: "post"
                }
            });

        case actionType.PUT_POINT_CONVERSION_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                pointConversionToast: {
                    message: 'Successfully updated point conversion!',
                    type: "success",
                    request: "put"
                }
            });

        case actionType.DELETE_POINT_CONVERSION_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                error: null,
                pointConversionToast: {
                    message: 'Successfully deleted point conversion!',
                    type: "success",
                    request: "delete"
                }
            });

        case actionType.DELETE_POINT_CONVERSION_FAILED:
        case actionType.PUT_POINT_CONVERSION_FAILED:
        case actionType.POST_POINT_CONVERSION_FAILED:
        case actionType.GET_POINT_CONVERSION_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                error: action.error,
                pointConversionToast: {
                    message: action.error ? action.error : 'Failed',
                    type: "failed",
                    request: "post"
                }
            });
        default:
            return state;
    }
}
